export function isTestEnv() {
  return process.env.NODE_ENV === 'development' || process.env.REACT_APP_IS_TEST_ENV;
}

export function isDevEnv() {
  return process.env.NODE_ENV === 'development';
}

export function isWhiteLabelEnv() {
  return process.env.REACT_APP_IS_WHITE_LABEL_ENV;
}
