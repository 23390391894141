import {Suspense, useState} from 'react';
import MiniGames from "src/MiniGames";
import LoadingScreen from "src/components/LoadingScreen";
import {isDevEnv} from "src/utilities/environment";

function Scene() {
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);

  function removeLoadingScreen() {
    setShowLoadingScreen(false)
  }

  return (
    <Suspense
      fallback={<LoadingScreen />}
    >
      {showLoadingScreen && !isDevEnv() && <LoadingScreen fadeOut onHidden={removeLoadingScreen} />}
      <MiniGames />
    </Suspense>
  )
}

export default Scene;
