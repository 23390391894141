import { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link, useLocation } from 'wouter';
import { getNameFromLocation, push } from '@ilmiworld/analytics';
import {
  getButtonEvent,
  validateText,
} from '@ilmiworld/analytics/dist/features/components/button';

import styles from './Button.module.scss';
import { analyticsEnabled, isDev, IS_IFRAME } from '../../constants';
import WorldMessageService from '../../services/WorldMessageService';

export function getButtonClasses(
  classes,
  color,
  fullWidth,
  hasIcon,
  small,
  extraSmall,
  large,
  extraLarge,
  isIconButton,
  underlined,
  preWrap,
  noPadding,
  disabled,
  responsive
) {
  return cn(styles.button, classes, {
    [styles.orange]: color === 'orange',
    [styles.darkBlue]: color === 'dark-blue',
    [styles.darkerBlue]: color === 'darker-blue',
    [styles.green]: color === 'green',
    [styles.red]: color === 'red',
    [styles.white]: color === 'white',
    [styles.redBlue]: color === 'redBlue',
    [styles.noColor]: color === 'none',
    [styles.fullWidth]: fullWidth,
    [styles.hasIcon]: hasIcon,
    [styles.small]: small,
    [styles.extraSmall]: extraSmall,
    [styles.extraLarge]: extraLarge,
    [styles.large]: large,
    [styles.isIconButton]: isIconButton,
    [styles.underlined]: underlined,
    [styles.whiteSpacePre]: preWrap,
    [styles.noPadding]: noPadding,
    [styles.disabled]: disabled,
    [styles.responsive]: responsive,
  });
}

function Button({
  onClick,
  onImpression,
  href,
  externalHref,
  ariaLabel,
  children,
  color = 'blue',
  fullWidth,
  IconComponent,
  small,
  extraSmall,
  large,
  extraLarge,
  inView,
  isIconButton,
  underlined,
  preWrap,
  noPadding,
  classes,
  disabled,
  responsive,
  ...props
}) {
  const classNames = getButtonClasses(
    classes,
    color,
    fullWidth,
    !!IconComponent,
    small,
    extraSmall,
    large,
    extraLarge,
    isIconButton,
    underlined,
    preWrap,
    noPadding,
    disabled,
    responsive
  );
  const [location] = useLocation();
  const text = validateText(children) || ariaLabel || '';

  useEffect(() => {
    if (inView && onImpression) {
      onImpression();
    } else if (analyticsEnabled && location && inView) {
      const event = getButtonEvent('button-impression', {
        name: getNameFromLocation(location),
        text,
      });

      if (IS_IFRAME) return WorldMessageService.sendAnalyticsEvent(event);
      return push(event, isDev);
    }
  }, [inView, onImpression]);

  const handleOnclick = (e) => {
    if (analyticsEnabled && !onImpression) {
      const event = getButtonEvent('button-click', {
        name: getNameFromLocation(location),
        text,
      });

      if (IS_IFRAME) WorldMessageService.sendAnalyticsEvent(event);
      else push(event, isDev);
    }

    if (onClick) onClick(e);
  };

  if (href || externalHref) {
    const ALink = (
      <a
        href={externalHref}
        className={classNames}
        aria-label={ariaLabel}
        aria-disabled={disabled ? 'true' : 'false'}
        target="_blank"
        {...props}
      >
        <span className={styles.content}>{children}</span>
        {IconComponent && (
          <span className={styles.iconContainer}>{IconComponent}</span>
        )}
      </a>
    );
    if (externalHref) return ALink;
    return (
      <Link href={href} aria-label={ariaLabel} disabled={disabled} {...props}>
        {ALink}
      </Link>
    );
  }

  return (
    <button
      onClick={handleOnclick}
      className={classNames}
      disabled={disabled}
      aria-label={ariaLabel}
      {...props}
    >
      <span className={styles.content}>{children}</span>

      {IconComponent && (
        <span className={styles.iconContainer}>{IconComponent}</span>
      )}
    </button>
  );
}

Button.propTypes = {
  ariaLabel: PropTypes.string,
  inView: PropTypes.bool,
  /** Callback function, this will disable default analytic events. */
  onImpression: PropTypes.func,
};

Button.defaultProps = {
  ariaLabel: undefined,
  inView: true,
  onImpression: undefined,
};

export default Button;
