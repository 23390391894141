/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */

// import styles from "./Main.module.scss"
import './main.scss';
import { useEffect, useState } from "react"


import bg_1 from '../../assets/images/bg_1.jpg'
import bg_2 from '../../assets/images/bg_2.jpg'
import bg_3 from '../../assets/images/bg_3.jpg'
import bg_4 from '../../assets/images/bg_4.jpg'
import gridimg from '../../assets/images/grid.png'

import mask_piece from '../../assets/images/pipes/mask.png'

import vertical_front from '../../assets/images/pipes/vertical_front.png'
import vertical_back from '../../assets/images/pipes/vertical_back.png'
import vertical_middle from '../../assets/images/pipes/vertical_middle.png'
import vertical_shadow from '../../assets/images/pipes/vertical_shadow.png'
import horizontal_front from '../../assets/images/pipes/horizontal_front.png'
import horizontal_back from '../../assets/images/pipes/horizontal_back.png'
import horizontal_middle from '../../assets/images/pipes/horizontal_middle.png'
import horizontal_shadow from '../../assets/images/pipes/horizontal_shadow.png'

import input_bottom_front from '../../assets/images/pipes/input_bottom_front.png'
import input_bottom_back from '../../assets/images/pipes/input_bottom_back.png'
import input_bottom_middle from '../../assets/images/pipes/input_bottom_middle.png'
import input_right_front from '../../assets/images/pipes/input_right_front.png'
import input_right_back from '../../assets/images/pipes/input_right_back.png'
import input_right_middle from '../../assets/images/pipes/input_right_middle.png'
import input_left_front from '../../assets/images/pipes/input_left_front.png'
import input_left_back from '../../assets/images/pipes/input_left_back.png'
import input_left_middle from '../../assets/images/pipes/input_left_middle.png'

import top_right_front from '../../assets/images/pipes/top_right_front.png'
import top_right_back from '../../assets/images/pipes/top_right_back.png'
import top_right_middle from '../../assets/images/pipes/top_right_middle.png'
import top_right_shadow from '../../assets/images/pipes/top_right_shadow.png'
import top_left_front from '../../assets/images/pipes/top_left_front.png'
import top_left_back from '../../assets/images/pipes/top_left_back.png'
import top_left_middle from '../../assets/images/pipes/top_left_middle.png'
import top_left_shadow from '../../assets/images/pipes/top_left_shadow.png'
import left_bottom_front from '../../assets/images/pipes/left_bottom_front.png'
import left_bottom_back from '../../assets/images/pipes/left_bottom_back.png'
import left_bottom_middle from '../../assets/images/pipes/left_bottom_middle.png'
import left_bottom_shadow from '../../assets/images/pipes/left_bottom_shadow.png'
import right_bottom_front from '../../assets/images/pipes/right_bottom_front.png'
import right_bottom_back from '../../assets/images/pipes/right_bottom_back.png'
import right_bottom_middle from '../../assets/images/pipes/right_bottom_middle.png'
import right_bottom_shadow from '../../assets/images/pipes/right_bottom_shadow.png'

import split_left_right_front from '../../assets/images/pipes/split_left_right_front.png'
import split_left_right_back from '../../assets/images/pipes/split_left_right_back.png'
import split_left_right_middle from '../../assets/images/pipes/split_left_right_middle.png'
import split_left_right_shadow from '../../assets/images/pipes/split_left_right_shadow.png'
import split_left_bottom_front from '../../assets/images/pipes/split_left_bottom_front.png'
import split_left_bottom_back from '../../assets/images/pipes/split_left_bottom_back.png'
import split_left_bottom_middle from '../../assets/images/pipes/split_left_bottom_middle.png'
import split_left_bottom_shadow from '../../assets/images/pipes/split_left_bottom_shadow.png'

import output_front from '../../assets/images/pipes/output_front.png'

import broken_hori_front from '../../assets/images/pipes/broken_hori.png'
import broken_verti_front from '../../assets/images/pipes/broken_verti.png'


import global from "../../global"
import * as PIXI from 'pixi.js'
import gsap from 'gsap'
import { PixiPlugin } from "gsap/PixiPlugin"

gsap.registerPlugin(PixiPlugin)
PixiPlugin.registerPIXI(PIXI)


function importAll(r) {
  return r.keys().map(r);
}

const end_animations = {
  fish: importAll(require.context('../../assets/images/targets/fish', false, /\.(png)$/)),
  melons: importAll(require.context('../../assets/images/targets/melons', false, /\.(png)$/)),
  tomatoes: importAll(require.context('../../assets/images/targets/tomatoes', false, /\.(png)$/)),
  bath: importAll(require.context('../../assets/images/targets/bath', false, /\.(png)$/)),
  city: importAll(require.context('../../assets/images/targets/city', false, /\.(png)$/)),
  house: importAll(require.context('../../assets/images/targets/house', false, /\.(png)$/)),
}

let pixiApp, stage, renderer

const lv = {}

lv.bg_assets = {
  bg_1: bg_1,
  bg_2: bg_2,
  bg_3: bg_3,
  bg_4: bg_4,
}

lv.pipe_assets = {
  vertical: {
    front: vertical_front,
    back: vertical_back,
    middle: vertical_middle,
    shadow: vertical_shadow,
  },
  horizontal: {
    front: horizontal_front,
    back: horizontal_back,
    middle: horizontal_middle,
    shadow: horizontal_shadow,
  },
  input_bottom: {
    front: input_bottom_front,
    back: input_bottom_back,
    middle: input_bottom_middle,
  },
  input_right: {
    front: input_right_front,
    back: input_right_back,
    middle: input_right_middle,
  },
  input_left: {
    front: input_left_front,
    back: input_left_back,
    middle: input_left_middle,
  },
  top_right: {
    front: top_right_front,
    back: top_right_back,
    middle: top_right_middle,
    shadow: top_right_shadow,
  },
  top_left: {
    front: top_left_front,
    back: top_left_back,
    middle: top_left_middle,
    shadow: top_left_shadow,
  },
  left_bottom: {
    front: left_bottom_front,
    back: left_bottom_back,
    middle: left_bottom_middle,
    shadow: left_bottom_shadow,
  },
  right_bottom: {
    front: right_bottom_front,
    back: right_bottom_back,
    middle: right_bottom_middle,
    shadow: right_bottom_shadow,
  },
  split_left_right: {
    front: split_left_right_front,
    back: split_left_right_back,
    middle: split_left_right_middle,
    shadow: split_left_right_shadow,
  },
  split_left_bottom: {
    front: split_left_bottom_front,
    back: split_left_bottom_back,
    middle: split_left_bottom_middle,
    shadow: split_left_bottom_shadow,
  },
  output: {
    front: output_front,
  },
  broken_hori: {
    front: broken_hori_front,
  },
  broken_verti: {
    front: broken_verti_front,
  },
}

let png_sequence_1, png_sequence_2

export default function Main() {

  const [BG, setBG] = useState({ background: 'url(' + lv.bg_assets['bg_' + 1] + ') center center / cover no-repeat' })

  useEffect(() => {
    global.emit.on('event', (data) => {
      manager.events.run(data)
      if(data.id === 'level_bg'){
        setBG({ background: 'url(' + lv.bg_assets['bg_' + data.value] + ') center center / cover no-repeat' })
      }
    })    
    app.init()
    resizer()
    window.addEventListener('resize', resizer)
    return () => {
      pixiApp.stop()
      global.emit.remove('event')
      window.removeEventListener('resize', resizer)
    }
  }, [])

  return (
    <div className="main_wrap" style={BG}>
      <canvas className='_canvas' id='_canvas' />
    </div>
  )
}

const app = {
  init: () => {
    pixiApp = new PIXI.Application({
      width: layout.width,
      height: layout.height,
      view: document.getElementById('_canvas'),
      backgroundAlpha: 0,
      resolution: 1
    })
    stage = pixiApp.stage
    renderer = pixiApp.renderer
    renderer.plugins.interaction.autoPreventDefault = false
    renderer.view.style.touchAction = 'auto'
    pixiApp.start()
    manager.init()
  },
}

const manager = {
  init: () => {
    layout.init()
    targets.init()
    pieces.init()
    manager.start()
    gsap.delayedCall(0.5, manager.water_text )
  },
  start: () => {
    global.emit.dispatch('event', { id: 'level_set', value: 1})
    //global.emit.dispatch('event', { id: 'level_started'})
  },
  water_text: () => {
    canvasText.init()
    canvasText.level_water_amounts()
  },
  events: {
    allowed: ['level_set', 'level_reset', 'level_next', 'level_time_up', 'level_restart', 'game_restart', 'level_finish_done'],
    run: (data) => {
      if(manager.events.allowed.includes(data.id)){
        manager.events[data.id](data.value)
      }
    },
    level_set: (nr) => {
      level.set(nr)
    },
    level_reset: () => {
      level.reset()
    },
    level_next: () => {
      // slots.forfeit_all()
      // level.next()     
      lv.tl_next = gsap.timeline()
      lv.tl_next.to('.main_wrap', { duration: 0.3, opacity: 0, ease: 'power2.inOut' })
      lv.tl_next.call(slots.forfeit_all)
      lv.tl_next.call(level.next)
      lv.tl_next.to('.main_wrap', { delay: 0.3, duration: 0.6, opacity: 1, ease: 'power2.inOut' })
    },
    level_time_up: () => {
      level.time_up()
      gsap.delayedCall(0.5, () => {
        global.emit.dispatch('event', { id: 'level_feedback', success: false })
      })
    },
    level_restart: () => {
      level.finished = false
      level.reset()
    },
    game_restart: () => {
      global.emit.dispatch('event', { id: 'level_bg', value: 1 })
      slots.forfeit_all()
      level.finished = false
      level.time_percentages = []
      level.set(1)
      canvasText.level_water_amounts()
    },
    level_finish_done: () => {
      if(level.cur === level.total){
        //console.log('game finished')
        global.emit.dispatch('event', { id: 'result_modal', stars: manager.stars(), showHide: 'show' })
      }else{
        global.emit.dispatch('event', { id: 'level_feedback', success: true })
      }  
    },
  },
  stars: () => {
    lv.stars = 1
    lv.all_percs = 0
    lv.nr = 0
    while(lv.nr < level.time_percentages.length){
      lv.all_percs += level.time_percentages[lv.nr]
      lv.nr++
    }
    lv.average_time = Math.round(lv.all_percs / lv.nr)
    if(lv.average_time > 30){
      lv.stars = 2
    }
    if(lv.average_time > 50){
      lv.stars = 3
    }
    if(lv.average_time > 70){
      lv.stars = 4
    }
    // console.log('calc_stars', 'average time: ' + lv.average_time, 'stars: ' + lv.stars)
    return lv.stars
  },
}

const layout = {
  /*
  HEIGHT: 7 x 128 = 896
  WIDTH: 5 x 128 = 640 + (2 x 40) 80 = 720
  */  
  width: 696,
  height: 896,
  blockSize: 128,
  blockPivot: 64,
  cols: 5,
  rows: 7,
  tilesTotal: 0,
  offsetMiddle: 30,
  boundsPointer: {top: 0, right: 0, bottom: 0, left: 0},
  init: () => {
    layout.tilesTotal = layout.cols * layout.rows
    layout.blockPivot = layout.blockSize * 0.5
    layout.bounds.set()
    slots.init()
    lv.contain_grid = new PIXI.Container()
    stage.addChild(lv.contain_grid)
    layout.populate()
  },
  bounds: {
    set: () => {
      layout.boundsPointer.top = 0
      layout.boundsPointer.right = layout.width - layout.offsetMiddle
      layout.boundsPointer.bottom = layout.height
      layout.boundsPointer.left = 0 + layout.offsetMiddle
    },
    check: (x, y) => {
      if(y < layout.boundsPointer.top){
        return false
      }
      if(y > layout.boundsPointer.bottom){
        return false
      }
      if(x < layout.boundsPointer.left){
        return false
      }
      if(x > layout.boundsPointer.right){
        return false
      }
      return true
    },
  },
  populate: () => {
    lv.contain_grid.removeChildren()
    // layout.grid.visual_tools()
    layout.grid.assets()
  },
  grid: {
    bg: () => {
      lv.bg = new PIXI.Graphics()
      lv.bg.lineStyle(2, 0x000000, 1)
      lv.bg.beginFill(0x0000000, 0.5);
      lv.bg.drawRect(0, 0, layout.width, layout.height)
      lv.bg.endFill()
      return lv.bg
    },
    tile: () => {
      lv.tl = new PIXI.Graphics()
      lv.tl.lineStyle(2, 0xffffff, 0.5)
      lv.tl.beginFill(0xffffff, 0.1);
      lv.tl.drawRect(-layout.blockPivot, -layout.blockPivot, layout.blockSize, layout.blockSize)
      lv.tl.endFill()
      //lv.tl.anchor.set(0.5)
      // lv.tl.alpha = 0
      return lv.tl
    },
    nr: (id) => {
      lv.tx = new PIXI.Text(id, {fill: '#ffffff'})
      lv.tx.anchor.set(0.5)
      lv.tx.alpha = 0.5
      return lv.tx
    },
    block: (obj) => {
      lv['gridblock_' + obj.id] = new PIXI.Container()
      lv['gridblock_' + obj.id].addChild(
        layout.grid.tile()
      )
      lv['gridblock_' + obj.id].addChild(
        layout.grid.nr(obj.id)
      )
      lv['gridblock_' + obj.id].x = obj.x
      lv['gridblock_' + obj.id].y = obj.y
      lv.contain_grid_blocks.addChild(lv['gridblock_' + obj.id])
    },
    visual_tools: () => {
      lv.contain_grid_bg = new PIXI.Container()
      lv.contain_grid_bg.addChild(
        layout.grid.bg()
      )
      lv.contain_grid.addChild(lv.contain_grid_bg)
      lv.contain_grid_blocks = new PIXI.Container()
      lv.contain_grid_blocks.x = layout.offsetMiddle
      lv.contain_grid.addChild(lv.contain_grid_blocks)

      lv.contain_grid_bg.alpha = 1
      lv.contain_grid_blocks.alpha = 0.3

      lv.nr = 0
      while(lv.nr < layout.tilesTotal){
        layout.grid.block(slots.map[lv.nr])
        lv.nr++
      }
    },
    assets: () => {
      lv.contain_gridImg = new PIXI.Container()
      lv.grid_lines = PIXI.Sprite.from(gridimg)
      lv.grid_lines.alpha = 0.5
      lv.contain_gridImg.addChild(lv.grid_lines)
      lv.contain_grid.addChild(lv.contain_gridImg)
    },
  }
}

const level = {
  cur: 1,
  total: 4,
  finished: false,
  puzzle_count: 0,
  output_amount: 0,
  time_percentages: [],
  resetable: {
    set: (value) => {
      if(value !== level.resetable.value){
        global.emit.dispatch('event', { id: 'level_resetable', value: value })
        level.resetable.value = value
      }
    },
    value: false,
  },
  set: (nr) => {
    level.cur = nr
    level.finished = false
    level.output_amount = 0
    level.end_animation()
    pieces.populate(nr)
    targets.populate(nr)
    gsap.delayedCall(0.1, () => {
      global.emit.dispatch('event', { id: 'level_water_amount', value: level.levels['level_' + level.cur].water_amount })
    })
  },
  reset: () => {
    level.resetable.set(false)
    slots.forfeit_all()
    pieces.reStartPos()
  },
  time_up: () => {
    level.resetable.set(false)
    level.finished = true
    drag_end()
  },
  time_log: () => {
    level.time_percentages.push(global.level_percentage)
    //console.log('time_log', level.time_percentages)
  },
  next : () => {
    //console.log('next level')
    lv.level_next = level.cur + 1
    level.resetable.set(false)
    level.set(lv.level_next)
    canvasText.level_water_amounts()
    global.emit.dispatch('event', { id: 'level_started'})
    global.emit.dispatch('event', { id: 'level_bg', value: lv.level_next })
  },
  levels: {
    /*
    places types 'broken_verti', 'broken_hori' as last in level order
    wfi = water_flow_immediate
    */
    level_1: {
      water_amount: 2,
      output_amounts: ['2'],
      pieces: [
        { id: 1, type: 'input_bottom', slot_start: 2, chain: true},
        { id: 2, type: 'vertical', slot_start: 4, chain: true},
        { id: 3, type: 'vertical', slot_start: 10, chain: true},
        { id: 4, type: 'top_right', slot_start: 12, chain: true},
        { id: 5, type: 'left_bottom', slot_start: 21, chain: true},
        { id: 6, type: 'vertical', slot_start: 24, chain: true},
        { id: 7, type: 'vertical', slot_start: 27, chain: true},        
        { id: 8, type: 'output', slot_start: 33},        
      ],
      targets: [{type: 'fish', slot: 33}]
    },
    level_2: {
      water_amount: 5,
      output_amounts: ['2','3'],
      pieces: [
        { id: 1, type: 'input_right', slot_start: 0, chain: true},
        { id: 2, type: 'vertical', slot_start: 3, chain: true},
        { id: 3, type: 'vertical', slot_start: 6, chain: true},
        { id: 4, type: 'vertical', slot_start: 10, chain: true},
        { id: 5, type: 'left_bottom', slot_start: 12, chain: true},
        { id: 6, type: 'vertical', slot_start: 14, chain: true},
        { id: 7, type: 'left_bottom', slot_start: 16, chain: true},
        { id: 8, type: 'split_left_right', slot_start: 18, chain: true},
        { id: 9, type: 'left_bottom', slot_start: 24, chain: true},
        { id: 10, type: 'right_bottom', slot_start: 25, chain: true},
        { id: 11, type: 'top_right', slot_start: 27, chain: true},
        { id: 12, type: 'output', slot_start: 30},
        { id: 13, type: 'output', slot_start: 33},
        { id: 14, type: 'broken_hori', slot_start: 26},
      ],
      targets: [{type: 'melons', slot: 30}, {type: 'tomatoes', slot: 33}]
    },
    level_3: {
      water_amount: 30,
      output_amounts: ['30'],
      pieces: [
        { id: 1, type: 'top_right', slot_start: 1, chain: true},
        { id: 2, type: 'right_bottom', slot_start: 3, chain: true},
        { id: 3, type: 'input_left', slot_start: 4, chain: true},
        { id: 4, type: 'top_right', slot_start: 5, chain: true},
        { id: 5, type: 'left_bottom', slot_start: 11, chain: true},
        { id: 6, type: 'top_left', slot_start: 14, chain: true},
        { id: 7, type: 'horizontal', slot_start: 15, chain: true},
        { id: 8, type: 'right_bottom', slot_start: 17, chain: true},
        { id: 9, type: 'left_bottom', slot_start: 19, chain: true},
        { id: 10, type: 'right_bottom', slot_start: 20, chain: true},
        { id: 11, type: 'vertical', slot_start: 22, chain: true},
        { id: 12, type: 'horizontal', slot_start: 27, chain: true},
        { id: 13, type: 'top_left', slot_start: 29, chain: true},
        { id: 14, type: 'horizontal', slot_start: 34, chain: true},
        { id: 15, type: 'output', slot_start: 30},
        { id: 16, type: 'broken_verti', slot_start: 7},
        { id: 17, type: 'broken_verti', slot_start: 16},
        { id: 18, type: 'broken_hori', slot_start: 23},
      ],
      targets: [{type: 'bath', slot: 30}]
    },
    level_4: {
      water_amount: 24000300,
      output_amounts: ['300', '24.000.000'],
      pieces: [
        { id: 1, type: 'top_left', slot_start: 0, chain: true},
        { id: 2, type: 'input_right', slot_start: 2, chain: true},
        { id: 3, type: 'left_bottom', slot_start: 6, chain: true},
        { id: 4, type: 'top_left', slot_start: 9, chain: true},
        { id: 5, type: 'top_right', slot_start: 12, chain: true},
        { id: 6, type: 'vertical', slot_start: 14, chain: true},
        { id: 7, type: 'right_bottom', slot_start: 16, chain: true},
        { id: 8, type: 'right_bottom', slot_start: 20, chain: true},
        { id: 9, type: 'vertical', slot_start: 22, chain: true},
        { id: 10, type: 'left_bottom', slot_start: 24, chain: true},
        { id: 11, type: 'split_left_bottom', slot_start: 26, chain: true},
        { id: 12, type: 'right_bottom', slot_start: 29, chain: true},
        { id: 13, type: 'output', slot_start: 31},
        { id: 14, type: 'output', slot_start: 33},
        { id: 15, type: 'broken_hori', slot_start: 4},
        { id: 16, type: 'broken_verti', slot_start: 11},
        { id: 17, type: 'broken_verti', slot_start: 19},
        { id: 18, type: 'broken_hori', slot_start: 21},
      ],
      targets: [{type: 'house', slot: 31}, {type: 'city', slot: 33}]
    },
  },
  finish: () => {
    level.finished = true    
    level.time_log()

    if(lv.irrigate){
      lv.irrigate.kill()
    }
    lv.irrigate = gsap.timeline({onComplete: () => {
      png_sequence_1.gotoAndPlay(1)
      if(png_sequence_2){
        png_sequence_2.gotoAndPlay(1)
      }
    }})

    level.waterflow()

    lv.durationTotal = lv.irrigate.totalDuration()

    lv.w8 = 4.5
    if(lv.water_amounts[1]){
      lv.w8 = 5.5
    }

    global.emit.dispatch('event', { id: 'level_finish', value: level.cur, ani_duration: lv.durationTotal, w8: lv.w8})

    gsap.delayedCall(lv.durationTotal + 1.5, level.end_animation_text)

  },
  waterflow: () => {
    lv.durationBase = 0.5

    lv.chain = []
    lv.rowchain = []
    lv.count_row = 0
    lv.count_col = 0
    lv.count_rowcol = 0
    lv.nr = 0
    while(lv.nr < layout.tilesTotal){
      // console.log('row', lv.count_row, 'col', lv.count_col)
      lv.slotcur = slots.map[lv.count_col]
      if(lv.slotcur){
        lv.havepart = lv.slotcur.occupied
        if(lv.havepart){
          if(lv.havepart.chain){
            lv.rowchain.push(lv.havepart)
          }
        }
      }
      lv.count_col++
      lv.count_rowcol++
      if(lv.count_rowcol === layout.cols){
        lv.chain.push(lv.rowchain)
        lv.rowchain = []
        lv.count_rowcol = 0
        lv.count_row++
      }
      lv.nr++
    }

    lv.nr = 0
    while(lv.nr < lv.chain.length){
      lv.nr2 = 0
      lv.duration = lv.durationBase * 0.1
      while(lv.nr2 < lv.chain[lv.nr].length){
        if(lv.nr2 > 0){
          lv.duration = lv.durationBase
        }
        lv.offset = '-=' + lv.duration
        lv.pid = lv.chain[lv.nr][lv.nr2].id - 1
        lv.targ = lv['pipe_' + lv.pid + '_mask'] 
        if(lv.targ){
          lv.irrigate.to(lv.targ, {duration: lv.durationBase, pixi: {scale: 1}, ease:'none'}, lv.offset)
        }
        lv.nr2++
      }
      lv.nr++
    }
  },
  end_animation: () => {

    png_sequence_1 = null
    png_sequence_2 = null

    lv.png_seq = []
    lv.trg = level.levels['level_' + level.cur].targets[0]
    
    end_animations[lv.trg.type].forEach((item) => {
      lv.png_seq.push(item.default)
    })
    lv.textureArray = []
    lv.png_seq.forEach((item) => {
      lv.tex = PIXI.Texture.from(item)
      lv.textureArray.push(lv.tex)
    })
    png_sequence_1 = new PIXI.AnimatedSprite(lv.textureArray)
    png_sequence_1.x = 100
    png_sequence_1.y = 400
    //stage.addChild(png_sequence_1)
    png_sequence_1.animationSpeed = 0.3
    png_sequence_1.loop = false
    png_sequence_1.onFrameChange = () => {
      if(png_sequence_1.currentFrame === png_sequence_1.totalFrames - 4){
        png_sequence_1.stop()
      }
    }
    png_sequence_1.gotoAndStop(0)


    lv.png_seq = []
    lv.trg = level.levels['level_' + level.cur].targets[1]
    if(!lv.trg){
      return
    }

    end_animations[lv.trg.type].forEach((item) => {
      lv.png_seq.push(item.default)
    })
    lv.textureArray = []
    lv.png_seq.forEach((item) => {
      lv.tex = PIXI.Texture.from(item)
      lv.textureArray.push(lv.tex)
    })
    png_sequence_2 = new PIXI.AnimatedSprite(lv.textureArray)
    png_sequence_2.x = 100
    png_sequence_2.y = 400
    //stage.addChild(png_sequence_2)
    png_sequence_2.animationSpeed = 0.3
    png_sequence_2.loop = false
    png_sequence_2.onFrameChange = () => {
      if(png_sequence_2.currentFrame === png_sequence_2.totalFrames - 4){
        png_sequence_2.stop()
      }
    }
    png_sequence_2.gotoAndStop(0)

  },
  end_animation_text: () => {
    lv.y = layout.blockSize
    gsap.to(lv['txtnr_' + 1], {duration: 0.5, pixi: {alpha: 1, scale: 1, y: '-=' + lv.y}, ease:'power3.inOut'})
    gsap.to(lv['txtnr_' + 1], {delay: 2.5, duration: 0.5, pixi: {alpha: 0, scale:0, y: '+=' + lv.y}, ease:'power3.inOut'})
    if(lv.water_amounts[1]){
      gsap.to(lv['txtnr_' + 2], {delay: 0.5, duration: 0.5, pixi: {alpha: 1, scale: 1, y: '-=' + lv.y}, ease:'power3.inOut'})
      gsap.to(lv['txtnr_' + 2], {delay: 3, duration: 0.5, pixi: {alpha: 0, scale:0, y: '+=' + lv.y}, ease:'power3.inOut'})
    }
  },
}

const canvasText = {
  style: {
    fontFamily: 'LoewNextArabic',
    fontSize: 100,
    fontWeight: 'bold',
    fill: '#ffffff',
    dropShadow: true,
    dropShadowColor: '#000000',
    dropShadowAlpha: 0.4,
    dropShadowBlur: 15,
    dropShadowAngle: Math.PI / 6,
    dropShadowDistance: 4
  },
  init: () => {
    lv.contain_text = new PIXI.Container()
    lv.contain_text.x = layout.offsetMiddle
    stage.addChild(lv.contain_text)
  },
  level_water_amounts: () => {
    lv.water_amounts = level.levels['level_' + level.cur].output_amounts;
    console.log(lv.water_amounts);
    lv.contain_text.removeChildren();

    lv.size = 'big'
    lv.amount_1 = -1
    lv.amount_2 = -1
    if(lv.water_amounts[0]){
      lv.amount_1 = level.levels['level_' + level.cur].output_amounts[0]
    }
    if(lv.water_amounts[1]){
      lv.amount_2 = level.levels['level_' + level.cur].output_amounts[1]
    }
    if(lv.amount_1.length > 2 || lv.amount_2.length > 2){
      lv.size = 'small'
    }

    if(lv.water_amounts[0]){
      canvasText.node(1, lv.size)
    }
    if(lv.water_amounts[1]){
      canvasText.node(2, lv.size)
    }

  },
  node: (nr, size) => {
    lv.amount = lv.water_amounts[nr - 1]
    if(size === 'big'){
      canvasText.style.fontSize = 100
    }
    if(size === 'small'){
      canvasText.style.fontSize = 40
    }
    lv['txtnr_' + nr] = new PIXI.Text(lv.amount, canvasText.style)
    lv['txtnr_' + nr].anchor.set(0.5)
    lv['txtnr_' + nr].scale.set(0.5)
    lv['txtnr_' + nr].alpha = 0
    lv['txtnr_' + nr].x = lv['output_' + nr].x
    lv['txtnr_' + nr].y = lv['output_' + nr].y
    lv.contain_text.addChild(lv['txtnr_' + nr])

  },
}


const slots = {
  init: () => {
    lv.nr = 0
    lv.x = layout.blockPivot
    lv.y = layout.blockPivot
    lv.countCols = 0
    while(lv.nr < layout.tilesTotal){
      slots.map[lv.nr] = {
        id: lv.nr,
        x: lv.x,
        y: lv.y,
        occupied: null,
      }
      lv.x += layout.blockSize
      lv.countCols++
      if(lv.countCols === layout.cols){
        lv.countCols = 0
        lv.x = layout.blockPivot
        lv.y += layout.blockSize
      }
      lv.nr++
    }
    slots.total = lv.nr
  },
  total: 0,
  map: {},
  occupy: (slot, piece) => {
    slots.map[slot].occupied = piece
  },
  forfeit: (slot) => {
    slots.map[slot].occupied = null
  },
  forfeit_all: () => {
    lv.nr = 0
    while(lv.nr < layout.tilesTotal){
      slots.forfeit(lv.nr)
      lv.nr++
    }
  },
}

const pieces = {
  active: null,
  init: () => {
    lv.contain_pieces = new PIXI.Container()
    lv.contain_pieces.sortableChildren = true
    lv.contain_pieces.x = layout.offsetMiddle
    stage.addChild(lv.contain_pieces)
    lv.blur1 = new PIXI.filters.BlurFilter()
    lv.blur1.blur = 5 
    pieces.assets()
    stage.interactive = true
    stage.on('pointerup', drag_end);
    stage.on('pointerupoutside', drag_end)
  },
  types: [
    {id: 'input_bottom', parts: ['back','middle','front'], count_in_puzzle: true, interactive: false, hasMask: true},
    {id: 'input_right', parts: ['back','middle','front'], count_in_puzzle: true, interactive: false, hasMask: true},
    {id: 'input_left', parts: ['back','middle','front'], count_in_puzzle: true, interactive: false, hasMask: true},
    {id: 'vertical', parts: ['shadow','back','middle','front'], count_in_puzzle: true, interactive: true, hasMask: true},
    {id: 'horizontal', parts: ['shadow','back','middle','front'], count_in_puzzle: true, interactive: true, hasMask: true},
    {id: 'top_right', parts: ['shadow','back','middle','front'], count_in_puzzle: true, interactive: true, hasMask: true},
    {id: 'top_left', parts: ['shadow','back','middle','front'], count_in_puzzle: true, interactive: true, hasMask: true},
    {id: 'left_bottom', parts: ['shadow','back','middle','front'], count_in_puzzle: true, interactive: true, hasMask: true},
    {id: 'right_bottom', parts: ['shadow','back','middle','front'], count_in_puzzle: true, interactive: true, hasMask: true},
    {id: 'split_left_right', parts: ['shadow','back','middle','front'], count_in_puzzle: true, interactive: true, hasMask: true},
    {id: 'split_left_bottom', parts: ['shadow','back','middle','front'], count_in_puzzle: true, interactive: true, hasMask: true},
    {id: 'output', parts: ['front'], count_in_puzzle: false, interactive: false, hasMask: false},
    {id: 'broken_hori', parts: ['front'], count_in_puzzle: false, interactive: false, hasMask: false},
    {id: 'broken_verti', parts: ['front'], count_in_puzzle: false, interactive: false, hasMask: false},
  ],
  assets: () => {
    lv.nr = 0
    while(lv.nr < pieces.types.length){
      lv.type = pieces.types[lv.nr].id
      lv.parts = pieces.types[lv.nr].parts
      lv.nr2 = 0
      while(lv.nr2 < lv.parts.length){
        lv.part = lv.parts[lv.nr2]
        lv['bt_' + lv.nr2] = new PIXI.BaseTexture(lv.pipe_assets[lv.type][lv.part])
        lv[lv.type + '_' + lv.part] = new PIXI.Texture(lv['bt_' + lv.nr2])
        lv.nr2++
      }
      lv.nr++
    }
    lv.mp = new PIXI.BaseTexture(mask_piece)
    lv.mask_piece = new PIXI.Texture(lv.mp)
  },
  populate: (id) => {
    lv.contain_pieces.removeChildren()
    lv.nr = 0
    lv.pieces = level.levels['level_' + id].pieces
    level.puzzle_count = 0
    while(lv.nr < lv.pieces.length){
      lv.piece = level.levels['level_' + id].pieces[lv.nr]
      lv['pipe_' + lv.nr] = new PIXI.Container()
      lv.type = lv.piece.type
      lv.type_src = pieces.types.find(o => o.id === lv.type)
      lv.parts = lv.type_src.parts
      lv.nr2 = 0
      while(lv.nr2 < lv.parts.length){
        lv.part = lv.parts[lv.nr2]
        lv['pipe_' + lv.nr + '_' + lv.part] = new PIXI.Sprite(lv[lv.type + '_' + lv.part])
        lv['pipe_' + lv.nr].addChild(lv['pipe_' + lv.nr + '_' + lv.part])
        lv.nr2++
      }
      if(lv.parts.includes('shadow')){
        pieces.shadow(lv.nr)
      }

      lv['pipe_' + lv.nr].piece_id = lv.piece.id
      lv['pipe_' + lv.nr].piece_count = lv.nr
      lv['pipe_' + lv.nr].type_src = lv.type_src
      lv['pipe_' + lv.nr].pivot.set(layout.blockPivot)

      pieces.startPos(lv.nr)

      if(lv.type_src.hasMask){
        lv['pipe_' + lv.nr + '_mask'] = new PIXI.Sprite(lv.mask_piece)
        lv['pipe_' + lv.nr].addChild(lv['pipe_' + lv.nr + '_mask'])
        lv['pipe_' + lv.nr + '_mask'].scale.y = 0
        lv['pipe_' + lv.nr + '_middle'].mask = lv['pipe_' + lv.nr + '_mask']
      }
      if(lv.type_src.interactive){
        lv['pipe_' + lv.nr].interactive = true
        lv['pipe_' + lv.nr].cursor = 'pointer'
        lv['pipe_' + lv.nr].on('pointerdown', drag_start, lv['pipe_' + lv.nr])
      }


      if(lv.type_src.count_in_puzzle){
        level.puzzle_count++
      }

      if(lv.type_src.id === 'output'){
        level.output_amount++
        lv.output_piece = level.levels['level_' + level.cur].pieces[lv.nr].slot_start
        lv['output_' + level.output_amount] = {
          slot_nr: lv.output_piece,
          x: slots.map[lv.output_piece].x,
          y: slots.map[lv.output_piece].y,
        }
        /*
        console.log( 'output has grid nr ' + lv.output_piece)
        console.log(lv['output_' + level.output_amount])
        */
      }

      lv.contain_pieces.addChild(lv['pipe_' + lv.nr])
      lv.nr++
    }
  },
  startPos: (nr) => {
    lv.piece = level.levels['level_' + level.cur].pieces[nr]
    lv['pipe_' + nr].x = slots.map[lv.piece.slot_start].x
    lv['pipe_' + nr].y = slots.map[lv.piece.slot_start].y
    lv['pipe_' + nr].slot_nr = lv.piece.slot_start
    slots.occupy(lv.piece.slot_start, lv.piece)
  },
  animateStartPos: (nr) => {
    lv.piece = level.levels['level_' + level.cur].pieces[nr]
    lv.x = slots.map[lv.piece.slot_start].x
    lv.y = slots.map[lv.piece.slot_start].y
    gsap.to(lv['pipe_' + nr], {delay: lv.delay, duration: 0.5, pixi: {x: lv.x, y: lv.y}, ease:'power4.inOut'})
    lv['pipe_' + nr].slot_nr = lv.piece.slot_start
    slots.occupy(lv.piece.slot_start, lv.piece)
  },
  reStartPos: () => {
    lv.delay = 0
    lv.nr = 0
    lv.pieces = level.levels['level_' + level.cur].pieces
    while(lv.nr < lv.pieces.length){
      pieces.animateStartPos(lv.nr)
      lv.delay += 0.01
      lv.nr++
    }
  },
  shadow: (nr) => {
    lv['pipe_' + nr + '_shadow'].alpha = 0
    lv['pipe_' + nr + '_shadow'].filters = [lv.blur1]
  },
  grab: (piece) => {
    gsap.to(pieces.active, {duration: 0.2, pixi: {scale: 1.15}, ease:'power4.inOut'})
    lv.shadow = lv['pipe_' + pieces.active.piece_count + '_shadow']
    gsap.to(lv.shadow, {duration: 0.2, pixi: {x: 10, y: 10, alpha: 0.4}, ease:'power4.inOut'})
  },
  snap: (piece) => {
    piece.zIndex = 1
    lv.x_dest = slots.map[piece.slot_nr].x
    lv.y_dest = slots.map[piece.slot_nr].y
    lv.withinBounds = layout.bounds.check(piece.x, piece.y)
    if(!lv.withinBounds){
      pieces.snapTo(piece, lv.x_dest, lv.y_dest)
      return
    }

    lv.piece_src = level.levels['level_' + level.cur].pieces.find(o => o.id === piece.piece_id)

    lv.slot_dest = null
    lv.dist_x = Infinity
    lv.dist_y = Infinity
    lv.nr = 0
    while(lv.nr < layout.tilesTotal){
      lv.dx = Math.abs(piece.x - slots.map[lv.nr].x)
      lv.dy = Math.abs(piece.y - slots.map[lv.nr].y)
      if(lv.dx <= lv.dist_x && lv.dy <= lv.dist_y){
        lv.dist_x = lv.dx
        lv.dist_y = lv.dy
        lv.slot_closest = lv.nr
      }
      lv.nr++
    }
    lv.slc = slots.map[lv.slot_closest]
    if(!lv.slc.occupied){
      lv.x_dest = lv.slc.x
      lv.y_dest = lv.slc.y
      slots.occupy(lv.slot_closest, lv.piece_src)
      slots.forfeit(piece.slot_nr)
      if(lv.slot_closest !== piece.slot_nr){
        if(!level.finished){
          level.resetable.set(true)
        }
      }
      piece.slot_nr = lv.slot_closest
    }
    pieces.snapTo(piece, lv.x_dest, lv.y_dest)
  },
  snapTo: (piece, x, y) => {
    lv.dsa = Math.abs(piece.x - x) + Math.abs(piece.y - y)
    lv.dur = 0.2 + (lv.dsa * 0.0005)
    gsap.to(piece, {duration: lv.dur, pixi: {x: x, y: y, scale: 1}, ease:'power4.inOut'})
    gsap.to(lv.shadow, {duration:lv.dur, pixi: {x: 0, y: 0, alpha: 0}, ease:'power4.inOut'})
    pieces.connections()
  },
  connections: () => {
    if(level.finished){
      return
    }
    // console.log(slots.map)
    lv.connected = 0
    lv.attach_count = 0
    lv.nr = 0
    while(lv.nr < level.puzzle_count){
      lv.ctype = lv['pipe_' + lv.nr].type_src.id
      lv.cslot = lv['pipe_' + lv.nr].slot_nr
      lv.connected = attach.check(lv.ctype, lv.cslot)
      // console.log(lv.nr + 1, lv['pipe_' + lv.nr].piece_id)
      if(lv.connected[0]){
        // console.log('piece ' + lv['pipe_' + lv.nr].piece_id + ' (type: ' + lv.ctype + ', slot: ' + lv.cslot + ') is connected to ', lv.connected[1])
        lv.attach_count++
      }
      lv.nr++
    }
    // console.log(lv.attach_count + ' / ' + level.puzzle_count + ' pieces attached')
    if(lv.attach_count === level.puzzle_count){
      // console.log('level finished')
      level.finish()
    }
  },
}

function drag_start(e) {
  if(level.finished){
    return
  }
  pieces.active = this
  pieces.active.zIndex = 100
  lv.offset = {
    x: e.data.global.x - pieces.active.x,
    y: e.data.global.y - pieces.active.y
  }
  pieces.grab(this)
  stage.on('pointermove', drag_move)
}
function drag_move(e) {
  if (pieces.active) {
    pieces.active.x = e.data.global.x - lv.offset.x
    pieces.active.y = e.data.global.y - lv.offset.y
  }
}
function drag_end() {
  if (pieces.active) {
    //console.log(pieces.active.piece_id, pieces.active.type_src)
    stage.off('pointermove', drag_move)
    pieces.snap(pieces.active)
    pieces.active = null
  }
}


const attach = {
  connections: {
    input_bottom: {
      adjacent_slots: ['bottom'],
      connectable_types: ['vertical', 'top_right'],
    },
    input_right: {
      adjacent_slots: ['right'],
      connectable_types: ['left_bottom'],
    },
    input_left: {
      adjacent_slots: ['left'],
      connectable_types: ['right_bottom', 'horizontal'],
    },
    vertical: {
      adjacent_slots: ['bottom'],
      connectable_types: ['vertical', 'top_left', 'top_right', 'output', 'split_left_right', 'split_left_bottom'],
    },
    horizontal: {
      adjacent_slots: ['left'],
      connectable_types: ['horizontal', 'right_bottom'],
    },
    top_right: {
      adjacent_slots: ['right'],
      connectable_types: ['left_bottom'],
    },
    top_left: {
      adjacent_slots: ['left'],
      connectable_types: ['right_bottom', 'horizontal'],
    },
    left_bottom: {
      adjacent_slots: ['bottom'],
      connectable_types: ['vertical', 'top_left', 'top_right', 'output', 'split_left_right', 'split_left_bottom'],
    },
    right_bottom: {
      adjacent_slots: ['bottom'],
      connectable_types: ['vertical', 'top_left', 'top_right', 'output', 'split_left_right', 'split_left_bottom'],
    },
    split_left_right: {
      adjacent_slots: ['left', 'right'],
      connectable_types: [],
    },
    split_left_bottom: {
      adjacent_slots: ['bottom', 'right'],
      connectable_types: [],
    },
    output: {
      adjacent_slots: ['bottom'],
      connectable_types: [],
    },
    broken_hori: {
      adjacent_slots: [],
      connectable_types: [],
    },
    broken_verti: {
      adjacent_slots: [],
      connectable_types: [],
    },
  },
  check: (type, slot) => {
    lv.fits = [false, false]

    //console.log('checking', slot, type)
    
    lv.slot_adjacent = -1
    if(attach.connections[type].adjacent_slots[0] === 'bottom'){
      lv.slot_adjacent = slot + layout.cols
    }
    if(attach.connections[type].adjacent_slots[0] === 'right'){
      lv.slot_adjacent = slot + 1
    }
    if(attach.connections[type].adjacent_slots[0] === 'left'){
      lv.slot_adjacent = slot - 1
    }

    if(slots.map[lv.slot_adjacent]){
      if(slots.map[lv.slot_adjacent].occupied){
        lv.fits = [attach.connections[type].connectable_types.includes(slots.map[lv.slot_adjacent].occupied.type), slots.map[lv.slot_adjacent].occupied]
      }
    }

    if(type === 'split_left_right'){
      lv.c_left = attach.check_slot(slots.map[slot - 1])
      lv.c_right = attach.check_slot(slots.map[slot + 1])
      //console.log('split_left_right', lv.c_left, lv.c_right)
      if(lv.c_left && lv.c_right){
        lv.fits = [true, true]
      }
    }

    if(type === 'split_left_bottom'){
      lv.c_left = attach.check_slot(slots.map[slot - 1])
      lv.c_right = attach.check_slot(slots.map[slot + layout.cols])
      //console.log('split_left_bottom', lv.c_left, lv.c_right)
      if(lv.c_left && lv.c_right){
        lv.fits = [true, true]
      }
    }

    // console.log('check: ', type, slot, lv.fits)
    return lv.fits
  },
  check_slot: (mslot) => {
    if(mslot){
      if(mslot.occupied){
        return mslot.occupied.type
      }
    }
    return false
  },
}

const targets = {
  init: () => {
    lv.contain_targets = new PIXI.Container()
    lv.contain_targets.x = layout.offsetMiddle
    stage.addChild(lv.contain_targets)
  },
  populate: (id) => {
    lv.contain_targets.removeChildren()

    lv.t = level.levels['level_' + id].targets[0]
    png_sequence_1.anchor.set(0.5)
    png_sequence_1.x = slots.map[lv.t.slot].x
    png_sequence_1.y = slots.map[lv.t.slot].y
    lv.contain_targets.addChild(png_sequence_1)

    lv.t = level.levels['level_' + id].targets[1]
    if(!lv.t){
      return
    }
    png_sequence_2.anchor.set(0.5)
    png_sequence_2.x = slots.map[lv.t.slot].x
    png_sequence_2.y = slots.map[lv.t.slot].y
    lv.contain_targets.addChild(png_sequence_2)
  },
}

/* ------------------------------------------------------------------------------- */

function getScaleFactor(w, h) {
  let pw = document.getElementById('_canvas').parentElement.clientWidth
  let ph = document.getElementById('_canvas').parentElement.clientHeight
  let nsw = pw / w
  let nsh = ph / h
  let newScale = 1
  if (nsw <= nsh) {
    newScale = nsw
  } else {
    newScale = nsh
  }
  if (newScale > 1) {
    // newScale = 1
  }
  return newScale
}
function resizer() {
  const gsf = getScaleFactor(layout.width, layout.height)
  // console.log(gsf)
  document.getElementById('_canvas').style.transform = 'scale(' + gsf + ')'
}

