export const IS_IFRAME = window.self !== window.top;

export const MINI_GAME_LINE_RIDER = 'line-rider';
export const MINI_GAME_ANIMATION_STUDIO = 'animation-studio';
export const MINI_GAME_TOWERS = 'towers';
export const MINI_GAME_FLIP_BOOK = 'flip-book';
export const MINI_GAME_SHADOWS = 'shadows';
export const MINI_GAME_CARGO_BOAT = 'cargo-boat';
export const MINI_GAME_CODE_BLOCKS = 'code-blocks';
export const MINI_GAME_CODING_CHALLENGE = 'coding-challenge';
export const MINI_GAME_IRRIGATION = 'irrigation';

export const TOYS = [MINI_GAME_LINE_RIDER, MINI_GAME_TOWERS, MINI_GAME_FLIP_BOOK, MINI_GAME_SHADOWS, MINI_GAME_CARGO_BOAT, MINI_GAME_CODE_BLOCKS, MINI_GAME_IRRIGATION];
export const DIGITAL_CHALLENGES = [MINI_GAME_ANIMATION_STUDIO, MINI_GAME_CODING_CHALLENGE];
