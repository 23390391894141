import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import InstructionsButton from '@ilmiworld/ui/src/components/InstructionsButton';
import ExitButton from 'src/components/ExitButton';

import styles from './Header.module.scss';

function Header({ onInfoClick, children, hasBackground }) {
  const { t } = useTranslation();
  const className = classNames(
    styles.component,
    hasBackground && styles.background
  );

  return (
    <>
      <header className={className}>
        {onInfoClick && (
          <InstructionsButton
            onClick={onInfoClick}
            ariaLabel={t('ui.info_btn')}
          />
        )}
        <div className="flex-grow-1">
          <div className="d-flex align-items-center justify-content-center">
            {children}
          </div>
        </div>
        <ExitButton />
      </header>
      <div className={styles.spacer} />
    </>
  );
}

export default Header;
