import styles from "./PieChart.module.scss";
import cn from "classnames"


export default function PieChart({percent}) {

  function calcTurns(percent) {
    const decimal = percent/100;
    if (percent > 50) {
      return ((decimal - 0.5).toString() + "turn");
    }
    else {
      return (decimal.toString() + "turn");
    }
  }

  return (
      <div>
        <div className={styles.container}>
          <div className={styles.outerPie}>
            <div className={styles.innerPie}>
              <div
                className={cn(
                  styles.counter,
                  {[styles.under]: (percent <= 50) },
                  {[styles.over]: (percent > 50) }
                )}
                style={{
                  transform: 'rotate(' + calcTurns(percent) + ')'
                }} />
            </div>
          </div>
        </div>
      </div>
    );
}
