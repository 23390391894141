import useAnalytics from '../hooks/useAnalytics';
import styles from '../../InstructionsModal.module.scss';

export default function InstructionsSlide({ text, image }) {
  const { ref } = useAnalytics();

  return (
    <div
      ref={ref}
      className={`${styles.slide} row justify-content-center align-items-center`}
    >
      <div className="col-12 order-0 col-lg-8 order-lg-1 col-xl-7">
        <p>{text}</p>
      </div>

      {image && (
        <div className="col-12 order-1 col-lg-4 order-lg-0 col-xl-5 d-flex justify-content-center">
          <img className="mw-100" src={image} alt={''} aria-hidden="true" />
        </div>
      )}
    </div>
  );
}
