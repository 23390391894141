var accents = /[\u0300-\u036f]/g;
var punctuation = /[!?()]/g;
var notAllowed = /[^a-zA-Z\d]/g;
var cleanText = function (text) {
    return typeof text === 'string'
        ? text
            .toLowerCase()
            .normalize('NFD')
            .replace(accents, '')
            .replace(punctuation, '')
        : '';
};
/** Cleans and replaces spaces and other characters with dashes. */
var parseText = function (text) {
    return typeof text === 'string' ? cleanText(text).replace(notAllowed, '-') : '';
};
var parseOptions = function (text) {
    if (typeof text === 'string') {
        var textArray = text.split('/').filter(Boolean);
        return textArray.map(function (item) { return parseText(item); }).join('/');
    }
    return '';
};
export default parseText;
export { cleanText, parseOptions };
