/* eslint-disable no-unused-vars */
import styles from './Irrigation.module.scss';
import Header from "./Components/UI/Header"
import {useTranslation} from 'react-i18next'
import { useEffect, useState } from "react";

import FeedbackModal from "src/components/FeedbackModal"
import RobotImageDizzy from "@ilmiworld/ui/src/assets/images/mascot/mascot_dizzy.png"
import RobotImageAmazing from "@ilmiworld/ui/src/assets/images/mascot/mascot_amazing.png"

import ResultModal from "src/MiniGames/Toys/Irrigation/Components/UI/ResultModal"

import Instructions from "./Components/UI/Instructions";
import Main from "./Components/Main";
import Footer from "./Components/UI/Footer";

import gsap from 'gsap'
import global from 'src/MiniGames/Toys/CodeBlocks/global'

function Irrigation() {
  const {t} = useTranslation()
  const [showInstructions, setShowInstructions] = useState(true)
  const [showFeedback, setShowFeedback] = useState(false)
  const [levelSuccess, setLevelSuccess] = useState(false)

  useEffect(()=>{

    // gsap.to('#wrapCanvas',{duration:0.5, opacity:1, ease:'power3.inOut'})
    global.emit.on('event', (data) => {
      if(data.id === 'level_feedback'){
        if(data.success){
          setLevelSuccess(true)
        }else{
          setLevelSuccess(false)
        }
        setShowFeedback(true)
      }
    })    
    return () => {
      global.emit.remove('event')
    }

  },[])

  function handleShowInstructions() {
    setShowInstructions(true)
    global.emit.dispatch('event', { id: 'level_paused'})
  }
  function handleCloseInstructions() {
    setShowInstructions(false)
    global.emit.dispatch('event', { id: 'level_resumed'})
  }

  function feedbackNext(){
    setShowFeedback(false)
    // console.log('feedback done')
    // console.log(levelSuccess)
    if(levelSuccess){
      global.emit.dispatch('event', { id: 'level_next'})
    }else{
      global.emit.dispatch('event', { id: 'level_restart'})
    }
  }

  function handleRestart(){
    // global.emit.fire('status_game', 'restart')
    console.log('RESTART GAME')
    global.emit.dispatch('event', { id: 'game_restart' })
    gsap.delayedCall(0.2, resultHide)
  }
  function resultHide(){
    global.emit.dispatch('event', { id: 'result_modal', showHide: 'hide' })
  }

  return (
    <>
      <Header onShowInstructions={handleShowInstructions} />
      <div className={styles.irrigation}>
        <div className={styles.pixiTextPrep}>0123456789</div>
        <Main/>
        <Footer/>
      </div>
      <FeedbackModal
        active={showFeedback}
        title={t(`irrigation.feedback_modal.${levelSuccess ? 'success' : 'failure'}.title`)}
        description={t(`irrigation.feedback_modal.${levelSuccess ? 'success' : 'failure'}.description`)}
        onNextClick={feedbackNext}
        resultImage={levelSuccess ? RobotImageAmazing : RobotImageDizzy}
        color={levelSuccess ? 'green' : 'red'}
      />
      <ResultModal onRestart={handleRestart} />
      <Instructions onClose={handleCloseInstructions} active={showInstructions} /> 
    </>
  );
}

export default Irrigation;
