/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import styles from "./Footer.module.scss"
import './footer.scss';
import { useEffect, useRef, useState} from "react"
import global from "../../../global"
import gsap from 'gsap'

import ResetIcon from 'src/MiniGames/Toys/CodeBlocks/assets/icons/icon_reset.svg'
import IconButton from "@ilmiworld/ui/src/components/IconButton"

import basin_black from '../../../assets/images/basin_black.png'
import basin_water from '../../../assets/images/basin_water.png'
import basin_pipe from '../../../assets/images/basin_pipe.png'

export default function Footer() {

  const resetDisable = useRef()
  const clippedWater = useRef()

  const [stateWaterAmount, setStateWaterAmount] = useState(0)
  const [stateWaterDuration, setStateWaterDuration] = useState(0)
  const [stateWaterCount, setStateWaterCount] = useState('')

  useEffect(() => {
    global.emit.on('event', (data) => {
      //console.log(data)
      if(data.id === 'level_water_amount'){
        setStateWaterAmount(data.value)
        setStateWaterCount('')
        basin_fill()
      }
      if(data.id === 'level_resetable'){
        if(data.value === true){
          reset_enable()
        }else{
          reset_disable()
        }
      }
      if(data.id === 'level_finish'){
        const water_duration = data.ani_duration
        setStateWaterDuration(water_duration)
        reset_disable()
        basin_drain(water_duration)
        setStateWaterCount('play')
        //console.log('level ' + data.value, 'dur: ' + data.ani_duration)
        gsap.delayedCall(water_duration + data.w8, () => {
          global.emit.dispatch('event', { id: 'level_finish_done' })
        })
      }
    })    
    return () => {
      global.emit.remove('event')
    }
  }, [])

  function reset_click(){
    global.emit.dispatch('event', { id: 'level_reset' })
    reset_disable()
  }
  function reset_enable(){
    gsap.to(resetDisable.current, { duration: 0.3, display: 'none', opacity: 0, ease: 'power4.inOut' })

  }
  function reset_disable(){
    gsap.to(resetDisable.current, { duration: 0.3, display: 'block', opacity: 0.5, ease: 'power4.inOut' })
  }

  function basin_drain(dur){
    gsap.to(clippedWater.current, { duration: dur, 'clip-path': 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)', ease: 'none' })
  }
  function basin_fill(){
    gsap.to(clippedWater.current, { duration: 0.5, 'clip-path': 'polygon(0% 0, 100% 0, 100% 100%, 0% 100%)', ease: 'none' })
  }

  return (
    <div className="irrigation_footer">
      <div className="ifoot_wrap">
        <div className="ifoot_wrap_btn">
          <IconButton
            onClick={reset_click}
            color="blue"
            icon={ResetIcon}
            withShadow
            flipRTL={false}
          />
          <div className="ifoot_reset_disable gpos" ref={resetDisable}></div>
        </div>
        <div className="ifoot_basin_wrap">
          <div className="basin_img_wrap">
            <img src={basin_black} alt="" />
            <div className="gpos clip_water" ref={clippedWater}>
              <img src={basin_water} alt="" />
            </div>
            <div className="gpos">
              <img src={basin_pipe} alt="" />
            </div>
            <div className="gpos ifoot_num_txt">
              <NumCounter state={stateWaterCount} amount={stateWaterAmount} duration={stateWaterDuration} />
            </div>
            <div className="gpos"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

const NumCounter = (props) => {
  const [num, setNum] = useState(0)
  function shiftNum() {
    const incNum = { num: props.amount }
    gsap.to(incNum, {
      duration: props.duration, num: 0, snap: 'num', ease: 'none', onUpdate: () => {
        setNum(incNum.num)
      }
    })
  }
  useEffect(() => {
    if (props.state === 'play') {
      shiftNum()
    }
  }, [props.state])

  useEffect(() => {
    setNum(props.amount)
  }, [props.amount])

  return (
    <span>
      {num}
    </span>
  )
}

