import Button from '@ilmiworld/ui/src/components/Button';

import styles from 'src/components/InstructionsModal/InstructionsModal.module.scss';
import useAnalytics from '../hooks/useAnalytics';

export default function InstructionsSlideEnd({
  text,
  image,
  buttonLabel,
  onClose,
}) {
  const { ref, inView } = useAnalytics(
    { stepOption: buttonLabel },
    'tool-start'
  );

  return (
    <div
      ref={ref}
      className={`${styles.slide} row justify-content-center align-items-center`}
    >
      <div className="col-12 order-0 col-lg-8 order-lg-1 col-xl-7">
        <p>{text}</p>
        <span className="d-none d-lg-block">
          <Button
            inView={inView}
            onClick={onClose}
            color={'orange'}
            fullWidth={true}
          >
            {buttonLabel}
          </Button>
        </span>
      </div>
      {image && (
        <div className="col-12 order-1 col-lg-4 order-lg-0 col-xl-5 d-flex justify-content-center">
          <img src={image} alt={''} aria-hidden="true" />
        </div>
      )}
      <div className="col-12 order-1 col-lg-4 order-lg-0 col-xl-5 d-flex justify-content-center d-lg-none">
        <Button
          inView={false} // see above `<Button />`, ignore tracking analytics event for this mobile button version.
          onClick={onClose}
          color={'orange'}
          fullWidth={true}
        >
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
}
