import styles from './App.module.scss';
import Scene from "src/scene/Scene";
import {initI18N} from "@ilmiworld/i18n/src/i18n";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import translations from 'src/locales'

function AppContent() {
  const { i18n } = useTranslation();

  return (
    <main className={styles.app} style={{ direction: i18n.dir() }}>
      <Scene />
    </main>
  );
}

function App() {
  const [i18NInitaliased, setI18NInitaliased] = useState(false);

  useEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');
    await initI18N(lang, translations);
    setI18NInitaliased(true);
  }, [])

  if (!i18NInitaliased) return null;

  return (
    <AppContent />
  )
}

export default App;
