import cn from 'classnames';
import ModalPortal from '../ModalPortal';
import { useEffect, useRef } from 'react';
import BootstrapModal from 'bootstrap/js/dist/modal';
import styles from './Modal.module.scss';

export default function Modal({
  active,
  onModelHidden,
  children,
  type,
  size = 'lg',
  scrollable,
  backgroundBlur,
  ...props
}) {
  const modalElementRef = useRef();
  const modalRef = useRef();
  const modalIsActive = useRef(false);

  useEffect(() => {
    const modalElement = modalElementRef.current;
    modalRef.current = BootstrapModal.getOrCreateInstance(modalElement);
    if (onModelHidden)
      modalElement.addEventListener('hidden.bs.modal', onModelHidden);

    return () => {
      if (onModelHidden)
        modalElement.removeEventListener('hidden.bs.modal', onModelHidden);
    };
  }, [onModelHidden]);

  useEffect(() => {
    const modal = BootstrapModal.getInstance(modalElementRef.current);
    if (modal && active && !modalIsActive.current) {
      modal.show();
    } else if (modal && !active && modalIsActive.current) {
      modal.hide();
    }
    modalIsActive.current = active;
  }, [modalIsActive.current, active]);

  const modalClassNames = cn(styles.modal, {
    [styles.error]: type === 'error',
    [styles.slim]: type === 'slim',
    [styles.transparent]: type === 'transparent',
    [styles.gradientRedBlue]: type === 'gradientRedBlue',
    [styles.gradientYellowRed]: type === 'gradientYellowRed',
    [styles.gradientBlue]: type === 'gradientBlue',
    [styles.red]: type === 'red',
    [styles.blue]: type === 'blue',
    [styles.backgroundBlur]: backgroundBlur === true,
  });

  const modalDialogClassNames = cn('modal-dialog modal-dialog-centered', {
    'modal-dialog-scrollable': scrollable,
    'modal-xl': size === 'xl',
    'modal-lg': size === 'lg',
    'modal-md': size === 'md',
    'modal-sm': size === 'sm',
    'modal-fullscreen': size === 'fullWidth' || size === 'fullScreen',
    [styles.fullWidthHeightFix]: size === 'fullWidth',
  });

  return (
    <ModalPortal>
      <div
        ref={modalElementRef}
        className={`modal fade ${modalClassNames}`}
        id="modal"
        aria-labelledby="modalTitle"
        tabIndex="-1"
        {...props}
      >
        <div className={modalDialogClassNames}>{children}</div>
      </div>
    </ModalPortal>
  );
}
