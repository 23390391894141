import getParentUrl from "src/utilities/getParentUrl";

export default class WorldMessageService {
  static generateMessage(payload) {
    return JSON.stringify(payload)
  }

  static sendAnalyticsEvent(event) {
    const message = this.generateMessage({ action: 'analytics', event })
    window.top.postMessage(message, process.env.REACT_APP_PARENT_URL)
  }

  static sendExitGame() {
    window.top.postMessage(this.generateMessage({action: 'exit'}), getParentUrl())
  }

  static sendGameStars(stars, game, score) {
    const payload = {
      action: 'earnStars',
      stars: stars,
      game: game,
      score: score
    }

    window.top.postMessage(this.generateMessage(payload), getParentUrl())
  }
}
