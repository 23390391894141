import { useTranslation } from 'react-i18next'

import WorldMessageService from "src/services/WorldMessageService";
import CloseButton from "@ilmiworld/ui/src/components/CloseButton";

export default function ExitButton() {
  const { t } = useTranslation();

  function handleExit() {
    WorldMessageService.sendExitGame();
  }

  return (
    <CloseButton
      color="red"
      onClick={handleExit}
      ariaLabel={t('ui.return_to_world_btn')}
    />
  )
}
