var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import dayjs from 'dayjs';
// eslint-disable-next-line import/no-cycle
import { push } from '../../dataLayer';
import { isDev, langRegex } from '../../../constants';
var getPageImpression = function (pageInfo) {
    var _a = window.document, title = _a.title, referrer = _a.referrer, lang = _a.documentElement.lang, _b = window.location, hostname = _b.hostname, origin = _b.origin, pathname = _b.pathname, search = _b.search, 
    // TODO(deprecated): replace platform, (https://stackoverflow.com/questions/69077747/how-to-determine-browser-platform-without-navigator-platform)
    _c = window.navigator, platform = _c.platform, userAgent = _c.userAgent;
    var url = origin + pathname;
    var filteredPathname = pathname.replace(langRegex, '');
    var sections = filteredPathname.split('/').filter(Boolean);
    var pageImpression = {
        event: 'page-impression',
        pageInfo: {
            domain: hostname,
            language: lang,
            name: 'steampark',
            params: search,
            path: pathname,
            platform: platform,
            referrer: referrer,
            section1: sections[0] || '',
            section2: sections[1] || '',
            section3: sections[2] || '',
            timestamp: dayjs().format(),
            title: title,
            url: url,
            userAgent: userAgent,
        },
    };
    return __assign(__assign({}, pageImpression), { pageInfo: __assign(__assign({}, pageImpression.pageInfo), pageInfo) });
};
var pushPageEvent = function (info, log) {
    if (log === void 0) { log = isDev; }
    var pageEvent = getPageImpression(info);
    return push(pageEvent, log);
};
export { getPageImpression, pushPageEvent };
