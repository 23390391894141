import Modal from "@ilmiworld/ui/src/components/Modal";
import ModalContent from "@ilmiworld/ui/src/components/ModalContent";
import ScoreModalContent from "src/components/ScoreModalContent";
import styles from "./ResultModal.module.scss";
import Button from "@ilmiworld/ui/src/components/Button";
import {useEffect, useState} from "react";
import {MINI_GAME_IRRIGATION} from "src/constants/miniGames";
import ExitButton from "src/components/ExitButton";
import { useTranslation } from 'react-i18next';

import global from "src/MiniGames/Toys/Irrigation/global"

export default function ResultModal({ onRestart }) {
  const { t } = useTranslation();
  const [active, setActive] = useState(false)
  const [stars, setStars] = useState(1)

  useEffect(() => {
    global.emit.on('event', (data) => {
      if(data.id === 'result_modal'){
        // console.log('result_modal', data)
        if(data.showHide === 'show'){
          setActive(true)
          setStars(data.stars)
        }else{
          setActive(false)
        }
      }
    })    
    return () => {
      global.emit.remove('event')
    }
  }, [])

  return (
    <Modal
      active={active}
      size="fullScreen"
      type="transparent"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <ModalContent>
        <div className={styles.container}>
          <div className={styles.resultModalHeader}>
            <div/>
            <ExitButton />
          </div>
          <div className={styles.resultModalBody}>
            <ScoreModalContent
              stars={stars}
              gameKey={MINI_GAME_IRRIGATION}
            />
          </div>
          <div className={styles.resultModalFooter}>
            <Button onClick={onRestart}>{t('ui.play_again_btn')}</Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}
