var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getGlobalInfo, push } from '../../dataLayer';
import { isDev } from '../../../constants';
import { parseOptions } from '../../utilities/parseText';
var getToolEvent = function (event, info) {
    var globalInfo = getGlobalInfo();
    var toolEvent = __assign({ event: event, info: __assign(__assign({}, info), { stepOption: parseOptions(info.stepOption) }) }, globalInfo);
    return toolEvent;
};
var pushToolEvent = function (event, info, log) {
    if (log === void 0) { log = isDev; }
    var toolEvent = getToolEvent(event, info);
    return push(toolEvent, log);
};
export { getToolEvent, pushToolEvent };
