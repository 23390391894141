import ReactDOM from 'react-dom';
import {useEffect, useRef} from "react";

const modalRoot = document.getElementById('modal-root');

export default function ModalPortal({ children }) {
  const el = useRef(document.createElement('div')).current;

  useEffect(() => {
    modalRoot.appendChild(el);

    return () => {
      modalRoot.removeChild(el);
    }
  }, [el])

  return ReactDOM.createPortal(
    children,
    el
  );
}

