import styles from "./Clock.module.scss"
import PieChart from "src/MiniGames/Toys/Shadows/Components/UI/PieChart"
import {useRef, useEffect, useState} from "react"
import {useTranslation} from 'react-i18next'
import global from "src/MiniGames/Toys/Irrigation/global"

import gsap from 'gsap'

const playTimeTotalSeconds = global.playTimeTotalSeconds
let startTime 
let percTween = {perc: 0}

export default function Clock() {

  const {t} = useTranslation()

  //const [counter, setCounter] = useState(null)
  const clockMapStart = {
    tick: 0,
    seconds: playTimeTotalSeconds,
    percentage: 0,
    tickFPS: 0,
    secondsFPS: playTimeTotalSeconds,
    percNew: 0
  }

  //const clockCount = useRef(gsap.ticker)
  const clockMap = useRef(JSON.parse(JSON.stringify(clockMapStart)))
  const [percentage, setPercentage] = useState(0)
  const [seconds, setSeconds] = useState(playTimeTotalSeconds)

  useEffect(() => {
    global.emit.on('event', (data) => {
      //console.log('clock', data)
      if(data.id === 'level_started'){
        clockReset()
        clockStart()
      }
      if(data.id === 'level_paused'){
        clockStop()
      }
      if(data.id === 'level_resumed'){
        clockStart()
      }
      if(data.id === 'level_finish'){
        clockStop()
      }
      if(data.id === 'level_reset'){
        clockReset()
      }
      if(data.id === 'level_restart'){
        clockReset()
        clockStart()
     }
      if(data.id === 'game_restart'){
        clockReset()
        clockStart()
     }
    })    
    return () => {
      global.emit.remove('event')
      clockStop()
    }
  }, [])

  useEffect(()=>{
    global.level_percentage = 100 - percentage
  },[percentage])

  function clockStart(){
    startTime = Date.now()
    gsap.ticker.add(clockFPS)
  }
  function clockStop(){
    gsap.ticker.remove(clockFPS)
  }
  function clockFinished(){
    clockStop()
    global.emit.dispatch('event', { id: 'level_time_up'})
  }
  function clockReset(){
    percTween = {perc: 0}
    setPercentage(0)
    setSeconds(playTimeTotalSeconds)
    clockMap.current = JSON.parse(JSON.stringify(clockMapStart))
  }
  function clockFPS(time, deltaTime, frame){
    let timer = Date.now()
    if (timer - startTime > 1000) {
      startTime = timer
      clockMap.current.seconds--
      setSeconds(clockMap.current.seconds)
      if(clockMap.current.seconds === 0){
        global.level_percentage = clockMap.current.percentage
        clockFinished()
      }
      let percNew = 100 - ( (clockMap.current.seconds / playTimeTotalSeconds) * 100 )
      gsap.to(percTween,{duration:1, perc: percNew, ease:'linear', onUpdate: () => {
        setPercentage(percTween.perc)
      }})
    }
  }

  return (
    <div className={styles.clockWrap}>
      <PieChart percent={percentage} />
      <div className={styles.timeWrap}>
        <div className={styles.secondsWrap}>{seconds}</div>
        <div>{t('shadows.seconds')}</div>
      </div>
    </div>
  )
}
