import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';

export const SUPPORTED_LANGUAGES = ['en', 'ar'];

function onChangeLanguage(lang) {
  dayjs.locale(lang);
  document.documentElement.setAttribute('lang', lang);
  document.documentElement.setAttribute('dir', i18n.dir(lang));
}

i18n.on('initialized', (options) => onChangeLanguage(options.lng));
i18n.on('languageChanged', (lng) => onChangeLanguage(lng));

export async function initI18N(lang, resources) {
  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      fallbackLng: 'ar',
      lng: lang || undefined,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
}
