const global = {
  emit : {
    on(event, callback) {
      document.addEventListener(event, (e) => callback(e.detail))
    },
    fire(event, data){
      global.status_game = data
      global.emit.dispatch(event, data)
    },
    dispatch(event, data) {
      document.dispatchEvent(new CustomEvent(event, { detail: data }))
    },
    remove(event, callback) {
      document.removeEventListener(event, callback)
    }
  },
  playTimeTotalSeconds: 90,
  level_percentage: 0,
  stars: 1,
}

export default global
