import Button from "../Button/Button";
import Icon from "../Icon";

export function getIconSize(small, extraSmall, large) {
  if (small) return 22;
  if (extraSmall) return 16;
  if (large) return 38;
  return 28;
}

function IconButton({ onClick, color = 'blue', fullWidth, icon, withShadow = true, flipRTL, ariaLabel, small, extraSmall, extraLarge, large, iconSize, responsive, ...props }) {
  const iconWidth = iconSize || getIconSize(small, extraSmall, large, extraLarge);
  return (
    <Button
      color={color}
      onClick={onClick}
      fullWidth={fullWidth}
      small={small}
      extraSmall={extraSmall}
      extraLarge={extraLarge}
      large={large}
      ariaLabel={ariaLabel}
      responsive={responsive}
      isIconButton
      {...props}
    >
      <Icon
        withShadow={withShadow}
        flipRTL={flipRTL}
      >
        <img src={icon} width={iconWidth} alt="" draggable={false} />
      </Icon>
    </Button>
  )
}

export default IconButton;
