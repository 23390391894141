import {
  MINI_GAME_ANIMATION_STUDIO,
  MINI_GAME_FLIP_BOOK,
  MINI_GAME_LINE_RIDER,
  MINI_GAME_TOWERS,
  MINI_GAME_SHADOWS,
  MINI_GAME_CARGO_BOAT,
  MINI_GAME_CODE_BLOCKS,
  MINI_GAME_CODING_CHALLENGE,
  MINI_GAME_IRRIGATION
} from '@ilmiworld/mini-games/src/constants/miniGames';

const locale = {
  ui: {
    return_to_world_btn: 'Return to world',
    info_btn: 'Show instructions',
    play_btn: 'Play',
    edit_btn: 'Edit',
    play_again_btn: 'Play again',
    pause_btn: 'Pause',
    return_btn: 'Return',
    next_step_btn: 'Go to next step',
    logo: 'Ilmi logo',
    blocks_alt_text: 'Block icon',
    wind_alt_text: 'Wind icon',
    skip_btn: 'Skip',
    exit_btn: 'Exit',
    modal: {
      close: 'Close modal',
    },
  },
  mascot: {
    hello: 'Istifham waving',
  },
  loading_screen: {
    rotate_device_text: 'Please rotate your screen for a better experience.',
    image_alt_text_toy: 'Istifham holding a controller',
    image_alt_text_digital_challenge: 'Istifham holding a trophy',
  },
  instructions: {
    close: 'Close instructions',
    next_slide: 'Next slide',
    previous_slide: 'Previous slide',
  },
  mini_games: {
    [MINI_GAME_LINE_RIDER]: 'Istifham Rider',
    [MINI_GAME_ANIMATION_STUDIO]: 'Istifham Studio',
    [MINI_GAME_TOWERS]: 'Istifham Tower',
    [MINI_GAME_FLIP_BOOK]: 'Flip Book',
    [MINI_GAME_SHADOWS]: 'Lights On',
    [MINI_GAME_CARGO_BOAT]: 'Stack It',
    [MINI_GAME_CODE_BLOCKS]: 'Code Blocks',
  },
  line_rider: {
    title: "Let's play!",
    reset_btn_label: 'Remove all lines',
    result_modal: {
      restart_btn: 'Play again',
      exit_btn: 'Exit',
      game_over: {
        title: 'Oops,\n try again!',
        image_alt_text: 'Robot looking dizzy',
      },
      score: {
        0: {
          title: 'Meh...',
          subtitle: 'Better luck next time...',
        },
        1: {
          title: 'Good!',
          subtitle: 'You are very energetic!',
        },
        2: {
          title: 'Great!',
          subtitle: 'Energy rocks!',
        },
        3: {
          title: 'Fantastic!',
          subtitle: 'Faster than light!',
        },
        4: {
          title: 'Amazing!',
          subtitle: 'There is a great engineer in you!',
        },
      },
    },
    instructions: {
      start: {
        title: 'Directions',
        text: 'Try your knowledge of kinetic energy!',
        button_label: 'Start the tour',
      },
      slide1: {
        text: 'Make Istifham hit the button as powerful as possible by drawing the best trajectory.',
      },
      slide2: {
        text: 'Use the slider to pan through the level while drawing lines.',
      },
      slide3: {
        text: 'Draw the line from the train to the button by using your finger or mouse.',
      },
      slide4: {
        text: "If you don't like your track, click the Undo button and try again.",
      },
      end: {
        text: "When you're done, click the Check button to get your score.",
        button_label: 'Play game',
      },
    },
  },
  animation_studio: {
    ui: {
      frame_btn: 'Frames',
    },
    frame_list: {
      add_btn: 'Add new frame',
      delete_btn: 'Delete frame',
      delete_modal_title: 'Delete?',
    },
    add_subject: {
      btn_label: 'Add Istifham',
      modal_title: 'Istifham',
    },
    add_object: {
      btn_label: 'Add object',
      modal_title: 'Objects',
    },
    add_background: {
      btn_label: 'Add background',
      modal_title: 'Backgrounds',
      add_custom_background_modal: {
        title: 'Add background',
        replace_title: 'Replace background',
        upload_btn: 'Upload',
        back_btn: 'Back',
        replace_warning:
          'Note that replacing this image will change the background in all previous frames with your custom background.',
      },
    },
    asset_modal: {
      add_btn: 'Add new',
      edit_btn: 'Replace item',
    },
    delete_modal: {
      delete_btn: 'Yes',
      exit_btn: 'No',
    },
    edit_object_toolbar: {
      flip_btn: 'Flip object',
      send_to_back_btn: 'Send object to back',
      send_to_front_btn: 'Bring object to front',
      delete_btn: 'Delete object',
      close_btn: 'Stop editing object',
    },
    change_fps_modal: {
      description:
        'Change the frames per second to change the speed of your movie. The lower the number, the bumpier your animation will appear. Try and see what happens!',
    },
    delete_all_frames: {
      btn_label: 'Reset scene',
      modal_title: 'Delete all frames?',
      modal_description:
        'Are you sure you want to delete all frames? You cannot undo this action.',
    },
    info_modal: {
      title: 'Instructions',
      instructions: {
        select_background: 'Choose the background <1/>',
        select_subject: 'Choose Istifham  <1/>',
        select_objects: 'Choose additional objects <1/>',
        place_elements:
          'Place the elements where you want by dragging and dropping them.',
        duplicate_frame:
          'When the composition is done, click on the <1/> button to duplicate the frame.',
        alter_frame:
          'Change everything you want inside the frame, making sure to move at least one element.',
        duplicate_again:
          'When the new frame is complete, click on the <1/> button to duplicate. Repeat this process until your movie is finished!',
        rotate_element:
          'You can rotate an element by clicking the <1/> button on the corner of the selection.',
        resize_element:
          'To resize an element, tap on it, then press and drag one of the squares in the corner of the selection.',
        flip_element:
          'You can flip an element by clicking the <1/> button on the bottom bar.',
        order_element:
          'You can move an element behind another one or in front of it by clicking one of these buttons <1/><2/> on the bottom bar.',
        delete_element:
          'You can delete an element by clicking the <1/> button on the bottom bar.',
        preview_movie:
          'You can watch the preview of your movie by clicking the <1/> button.',
        change_fps:
          'You can change the speed of your movie by clicking the <1/> button.',
        delete_frame:
          'You can delete a frame by clicking on the <1/> button near the frame preview.',
      },
      remember_title: 'Remember',
    },
    download_video_modal: {
      title: 'Are you sure you want to download your video?',
      yes_btn: 'Yes',
      no_btn: 'No',
      description: 'Remember to share it ',
    },
    font_weight_picker: {
      normal: 'Make font weight normal',
      bold: 'Make font weight bold',
    },
    intro_creator_page: {
      title: 'Choose your title',
      title_max_length: '50 characters max',
      no_title_error:
        'Your title page is still blank. Please add a title before you continue.',
    },
    outro_creator_page: {
      title: 'Create your outro',
      title_max_length: '20 characters max',
      no_title_error:
        'Your outro page is still blank. Please write an outro before you continue.',
    },
    video_generator: {
      generating_video: 'Your video is being generated, please wait.',
    },
    instructions: {
      start: {
        title: 'Directions',
        text: 'Animation Studio tool',
        button_label: 'Start the tour',
      },
      slide1: {
        text: 'To start, choose a catchy title for your animation. You can change your title at any time”',
      },
      slide2: {
        text: 'Change, edit and play your frames and animation by these buttons.',
      },
      slide3: {
        text: 'Change, edit and play your frames and animation by these buttons.',
      },
      slide4: {
        text: 'Satisfied with the first frame? Click the plus button to add more frames.',
      },
      slide5: {
        text: 'Change one or more objects in each frame to create your own story.',
      },
      slide6: {
        text: 'You can move objects, but also enlarge or rotate them.',
      },
      slide7: {
        text: 'Change the framerate to make your animation run smoothly.',
      },
      slide8: {
        text: 'With the play button you can preview your animation at any time.',
      },
      end: {
        text: "When you're satisfied, click on the Check button to generate your animation video and if you want download it.",
        button_label: 'Start creating',
      },
    },
    browser_warning: {
      title: 'Attention!',
      description:
        'It appears your browser does not support generating a video. This means that if you try to download and play your video, it will show a black screen. Upgrading to the latest browser version should fix this problem.',
    },
  },
  towers: {
    reset_btn_label: 'Restart',
    instructions: {
      start: {
        title: 'Directions',
        text: 'Test your knowledge of horizontal and vertical forces!',
        button_label: 'Start the tour',
      },
      slide1: {
        text: "Test the tower's stability against the forces of wind and gravity.",
      },
      slide2: {
        text: 'Remove as many blocks as possible without making the tower fall.',
      },
      slide3: {
        text: 'To start over, click the Reset button.',
      },
      slide4: {
        text: 'When you have finished removing blocks, click the Check button to proceed to the next step.',
      },
      slide5: {
        text: 'Adjust the slider to increase the wind speed. The stronger the wind, the more points you earn.',
      },
      slide6: {
        text: 'Check the timer on top and try to get your tower to stand for 20 seconds. You can adjust the wind speed at any time.',
      },
      end: {
        text: 'Your final score will be a combination of the number of blocks you remove in the first step and the wind speed you apply in the second step.',
        button_label: 'Play game',
      },
    },
    result_modal: {
      your_score: 'Your score:',
      multipliers: 'Multipliers',
      total_score: 'Total score',
      game_over: {
        title: 'Oops..',
        subtitle: 'try again!',
      },
      score: {
        1: {
          title: 'Meh...',
          subtitle: 'You can do better...',
        },
        2: {
          title: 'Good!',
          subtitle: 'You did it!',
        },
        3: {
          title: 'Great!',
          subtitle: 'Stable performance!',
        },
        4: {
          title: 'Amazing!',
          subtitle: 'Rock solid!',
        },
      },
    },
    feedback_modal: {
      success_title: 'Oh no!',
      failure_title: 'Great',
      success_feedback:
        "Your building didn't pass the gravity test. You removed too many blocks from critical places!",
      failure_feedback:
        "Let's see what happens to your building with the wind force! The stronger the wind, the more points you earn.",
    },
  },
  [MINI_GAME_FLIP_BOOK]: {
    reset_btn_label: 'Restart',
    instructions: {
      start: {
        title: 'Directions',
        text: 'Test your knowledge of animation!',
        button_label: 'Start the tour',
      },
      slide1: {
        text: "Oh no! All the frames of Istifham's movie are mixed up. Can you help him recreate his favourite animation?",
      },
      slide2: {
        text: 'The first frame, marked with a star, is already placed.',
      },
      slide3: {
        text: 'To reposition a frame, hold it until it pops off the timeline. Then drag it to the place you think it belongs.',
      },
      slide4: {
        text: 'Click the Play button to preview your animation and see whether all the frames are in the right place.',
      },
      slide5: {
        text: 'If you want, you can start over by clicking the reset button.',
      },
      end: {
        text: "When you're done, click the Check button to get the score.",
        button_label: 'Play game',
      },
    },
    level: 'Level {{level}}',
    feedback_modal: {
      success: {
        title: 'Well done!',
        description:
          'The order is correct! Play the next round and see how you do when things get harder!',
      },
      failure: {
        title: 'Oh no!',
        description:
          "Unfortunately, the order isn't correct. Try again, paying attention to small details in each frame. You can do it!",
      },
    },
    result_modal: {
      your_score: 'Your score:',
      multipliers: 'Multipliers',
      total_score: 'Total score',
      game_over: {
        title: 'Oops..',
        subtitle: 'Try again!',
      },
      score: {
        1: {
          title: 'Meh...',
          subtitle: 'You can do better...',
        },
        2: {
          title: 'Good!',
          subtitle: 'You did it!',
        },
        3: {
          title: 'Great!',
          subtitle: 'Your movie rocks!',
        },
        4: {
          title: 'Fantastic!',
          subtitle: 'Best movie ever!',
        },
      },
    },
  },
  [MINI_GAME_SHADOWS]: {
    level: 'Level {{levelNR}}',
    seconds: 'sec',
    instructions: {
      start: {
        title: 'Directions',
        text: 'Test your knowledge of shapes and shadows.',
        button_label: 'Start the tour',
      },
      slide1: {
        text: 'Oh no! The light went out and Istifham broke his statue by mistake! Help him rebuild it.',
      },
      slide2: {
        text: 'You have 90 seconds to rebuild the statue before the light goes out again.',
      },
      slide3: {
        text: "Drag the shapes into the right place inside the statue's silhouette.",
      },
      slide4: {
        text: 'Tap or click on the shape to rotate it.',
      },
      end: {
        text: 'Your final score will depend on your speed in rebuilding the statue.',
        button_label: 'Play game',
      },
    },
    indicator: {
      rotate: {
        text: 'Tap a shape\n to rotate',
      },
    },
    feedback_modal: {
      success: {
        title: 'Well done!',
        description:
          'You rebuilt the shape in time! Play the next round and see how you do when things get harder!',
      },
      failure: {
        title: 'Oh no!',
        description:
          "Unfortunately, you didn't rebuild the statue in time.\n Try again and see if you can be quicker. You can do it!",
      },
    },
    result_modal: {
      game_over: {
        title: 'Oops!',
        subtitle: 'Try again!',
      },
      score: {
        1: {
          title: 'Meh...',
          subtitle: 'You can do better...',
        },
        2: {
          title: 'Good!',
          subtitle: 'You did it!',
        },
        3: {
          title: 'Great!',
          subtitle: 'Your a shapeshifter!',
        },
        4: {
          title: 'Fantastic!',
          subtitle: 'Faster than light!',
        },
      },
    },
  },
  [MINI_GAME_CARGO_BOAT]: {
    seconds: 'sec',
    instructions: {
      start: {
        title: 'Directions',
        text: 'Test your knowledge of buoyancy and density.',
        button_label: 'Start tour',
      },
      slide1: {
        text: 'Istifham wants to send some goods to his friends across the ocean. Can you help?',
      },
      slide2: {
        text: 'The grabber moves continuously from side to side.',
      },
      slide3: {
        text: 'When the cargo is above the location you want it to be placed, tap the screen to release it.',
      },
      slide4: {
        text: 'The two displays in the grabber show you which cargo will be next in line',
      },
      slide5: {
        text: 'Earn points by placing as much cargo on the ship as possible',
      },
      slide6: {
        text: 'Load as much cargo as possible before time runs out...',
      },
      slide7: {
        text: 'Happy with your result? Press the Sail button to leave the harbor',
      },
      slide8: {
        text: 'If the red line touches the water, the ship is unbalanced or too heavy, and will sink. Try to load the ship evenly and keep the red line from touching the water.',
      },
      end: {
        text: 'Good luck, sailor!',
        button_label: 'Play game',
      },
    },
    finish_modal: {
      time_up: "Time's up!",
      set_sail: {
        title: 'Set sail',
        text: 'Happy with your result and ready to set sail?',
        button_confirm: 'Yes',
        button_refute: 'No',
      },
      result: {
        score_total: 'Total score:',
        cargo_total: 'Total cargo:',
      },
    },
    result_modal: {
      game_over: {
        title: 'Oops!',
        subtitle: 'Try again!',
      },
      score: {
        1: {
          title: 'Meh...',
          subtitle: 'You can do better...',
        },
        2: {
          title: 'Good!',
          subtitle: 'You did it!',
        },
        3: {
          title: 'Great!',
          subtitle: "You're a good stacker!",
        },
        4: {
          title: 'Fantastic!',
          subtitle: "You're a stack master!",
        },
      },
    },
  },
  [MINI_GAME_CODE_BLOCKS]: {
    instructions: {
      start: {
        title: 'Directions',
        text: 'Test your logic.',
        button_label: 'Start tour'
      },
      end: {
        text: 'Good luck, coder!',
        button_label: 'Play game',
      },
      slide1: {
        text: 'Istifham lost four batteries, can you help to collect them all?',
      },
      slide2: {
        text: 'Guide Istifham through the maze by creating a sequence of movement-blocks.',
      },
      slide3: {
        text: 'Add a movement-block to the sequence by pressing the plus button.',
      },
      slide4: {
        text: 'At the top of the screen, you see the maximum amount of movement-blocks that can be used. One block corresponds to one energy-step.',
      },
      slide5: {
        text: 'Use the loop function to repeat the chosen movement-block and save energy-steps.',
      },
      slide6: {
        text: 'Click the Play button to run the sequence and see whether all the movement-blocks are in the right place.',
      },
    },
    feedback_modal: {
      success: {
        title: 'Well done!',
        description:
          "You successfully reached the checkpoint and recharged my battery! Let's keep going!",
      },
      failure: {
        title: 'Oh no!',
        description:
          "Unfortunately, you're out of energy! Try again from the last check point.",
      },
    },
    result_modal: {
      game_over: {
        title: 'Oops!',
        subtitle: 'Try again!',
      },
      score: {
        1: {
          title: 'Meh',
          subtitle: 'You can do better...',
        },
        2: {
          title: 'Good!',
          subtitle: 'You did it.',
        },
        3: {
          title: 'Great!',
          subtitle: "You're a good coder!",
        },
        4: {
          title: 'Fantastic!',
          subtitle: "You're a code master!",
        },
      },
    },
  },
  [MINI_GAME_CODING_CHALLENGE]: {
    instructions: {
      start: {
        title: 'Directions',
        text: 'Try your knowledge of Coding Music!',
        button_label: 'Start the tour',
      },
      end: {
        text: 'When you\'re done, click on the Check button to listen the melody and if you\'re satisfied download it. Headphones are recommended.',
        button_label: 'Play the challenge',
      },
      slide1: {
        text: 'Create your melody by combining these five instruments.',
      },
      slide2: {
        text: 'Click on Create and add sound by pressing the plus icon.',
      },
      slide3: {
        text: 'Click on Edit and change the effect and/or pitch of your sound.',
      },
      slide4: {
        text: 'Click on Play and change the beats per minute to change the speed of the music.',
      },
      slide5: {
        text: 'With the play button you can listen to your music at any time.',
      },
    },
  },
  [MINI_GAME_IRRIGATION]: {
    instructions: {
      start: {
        title: 'Directions',
        text: 'Test your irrigation skills',
        button_label: 'Start the tour'
      },
      end: {
        text: 'Your result will be overwhelming.',
        button_label: 'Play game'
      },
      slide1: {
        text: 'The irrigation systems have broken down, can you help Istifham to repair it?',
      },
      slide2: {
        text: 'Drag the waterpipes to the right place to reconnect them.',
      },
      slide3: {
        text: 'Reconnect the circuit within the given time to reach the checkpoint.',
      },
    },
    feedback_modal: {
      success: {
        title: 'Well done!',
        description: 'You successfully created a complete irrigation system and reached the checkpoint! Let\'s  keep going!',
      },
      failure: {
        title: 'Oh no!',
        description: 'Unfortunately, you ran out of time. Try again from your last checkpoint!',
      }
    },
    result_modal: {
      game_over: {
        title: 'Oops!',
        subtitle: 'Try again!',
      },
      score: {
        1: {
          title: 'Meh',
          subtitle: 'You can do better...',
        },
        2: {
          title: 'Good!',
          subtitle: 'You did it.',
        },
        3: {
          title: 'Great!',
          subtitle: 'You\'re a great irrigator!',
        },
        4: {
          title: 'Fantastic!',
          subtitle: 'You\'re an irrigation master!',
        },
      }
    }
  },
  frame_list: {
    go_to_frame: 'Go to frame {{frame}}',
  },
  stars: {
    achieved_star: 'Achieved star',
    empty_star: 'Empty star',
  },
};

export default locale;
