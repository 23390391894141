import cn from 'classnames';
import styles from './LoadingScreen.module.scss';
import { useTranslation } from 'react-i18next';
import {isMobileOrTablet} from "src/utilities/system";
import isPortrait from "src/utilities/isPortrait";
import Icon from "@ilmiworld/ui/src/components/Icon";
import {ReactComponent as RotateDeviceIcon} from "@ilmiworld/ui/src/assets/icons/icon_rotate_device.svg";
import toyImage from "./assets/toy_loader_image.svg";
import digitalChallengeImage from "./assets/digital_challenge_loader_image.png";
import getMiniGame from "src/utilities/getMiniGame";
import isLandscapeGame from "src/utilities/isLandscapeGame";
import {DIGITAL_CHALLENGES} from "src/constants/miniGames";

export default function LoadingScreen({ fadeOut, onHidden }) {
  const { t } = useTranslation();
  const currentMiniGame = getMiniGame();
  const currentGameIsDigitalChallenge = DIGITAL_CHALLENGES.includes(currentMiniGame);
  const loadingScreenClassNames = cn(styles.loadingScreen, {
    [styles.fadeOut]: fadeOut,
    [styles.digitalChallenge]: currentGameIsDigitalChallenge,
  })

  function handleAnimationEnded() {
    onHidden();
  }

  return (
    <div
      className={loadingScreenClassNames}
      onAnimationEnd={handleAnimationEnded}
    >
      <div className={styles.illustration}>
        <div className='ratio ratio-1x1'>
          <div className={styles.imageContainer}>
            <img
              src={currentGameIsDigitalChallenge ? digitalChallengeImage : toyImage}
              alt={t(`loading_screen.image_alt_text_${currentGameIsDigitalChallenge ? 'digital_challenge' : 'toy'}`)}
              className={styles.image}
            />
          </div>
        </div>
      </div>
      <h1 className={styles.title}>{t(`mini_games.${process.env.REACT_APP_MINI_GAME}`)}</h1>
      {(isMobileOrTablet() && isPortrait() && isLandscapeGame(process.env.REACT_APP_MINI_GAME)) && (
        <div className={styles.rotateDeviceNotice}>
          <Icon>
            <RotateDeviceIcon width="71.38" height="60.2" />
          </Icon>
          <p>{t('loading_screen.rotate_device_text')}</p>
        </div>
      )}
    </div>
  );
}
