import IconButton from '@ilmiworld/ui/src/components/IconButton';
import CrossIcon from '@ilmiworld/ui/src/assets/icons/icon_cross.svg';

function CloseButton({
  onClick,
  ariaLabel,
  responsive,
  color = 'orange',
  ...props
}) {
  return (
    <IconButton
      onClick={onClick}
      color={color}
      icon={CrossIcon}
      ariaLabel={ariaLabel}
      responsive={responsive}
      small
      {...props}
    />
  );
}

export default CloseButton;
