const global = {
  emit : {
    on(event, callback) {
      document.addEventListener(event, (e) => callback(e.detail))
    },
    fire(event, data){
      global.status_game = data
      global.emit.dispatch(event, data)
    },
    dispatch(event, data) {
      document.dispatchEvent(new CustomEvent(event, { detail: data }))
    },
    remove(event, callback) {
      document.removeEventListener(event, callback)
    }
  },
  stars: 0,
  playSteps: 20,
  playStepsTotal: 20,
  speedMove: 0.6,
  status_game: 'init',
  sequence_running: false,
  stats : {},
}

export default global
