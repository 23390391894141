var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getGlobalInfo, push } from '../../dataLayer';
import { isDev } from '../../../constants';
import parseText from '../../utilities/parseText';
var validateText = function (text) { return (typeof text === 'string' ? text : ''); };
var getButtonEvent = function (event, buttonInfo) {
    var globalInfo = getGlobalInfo();
    var buttonEvent = __assign({ event: event, info: __assign(__assign({}, buttonInfo), { text: parseText(buttonInfo.text) }) }, globalInfo);
    return buttonEvent;
};
var pushButtonEvent = function (event, buttonInfo, log) {
    if (log === void 0) { log = isDev; }
    var buttonEvent = getButtonEvent(event, buttonInfo);
    return push(buttonEvent, log);
};
export { getButtonEvent, pushButtonEvent, validateText };
