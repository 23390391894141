import React from 'react';
import {
  MINI_GAME_ANIMATION_STUDIO,
  MINI_GAME_FLIP_BOOK,
  MINI_GAME_LINE_RIDER,
  MINI_GAME_TOWERS,
  MINI_GAME_CARGO_BOAT,
  MINI_GAME_SHADOWS,
  MINI_GAME_CODE_BLOCKS,
  MINI_GAME_CODING_CHALLENGE, MINI_GAME_IRRIGATION
} from "src/constants/miniGames";

import getMiniGame from "src/utilities/getMiniGame";
import Irrigation from "src/MiniGames/Toys/Irrigation";

const CargoBoat = React.lazy(() => import("src/MiniGames/Toys/CargoBoat"));
const FlipBook = React.lazy(() => import("src/MiniGames/Toys/FlipBook"));
const Towers  = React.lazy(() => import('src/MiniGames/Toys/Towers'));
const Shadows  = React.lazy(() => import('src/MiniGames/Toys/Shadows'));
const LineRider = React.lazy(() => import('src/MiniGames/Toys/LineRider'));
const AnimationStudio = React.lazy(() => import('src/MiniGames/DigitalChallenges/AnimationStudio'));
const CodeBlocks = React.lazy(() => import('src/MiniGames/Toys/CodeBlocks'));
const CodingChallenge = React.lazy(() => import('src/MiniGames/DigitalChallenges/CodingChallenge'));

export default function MiniGames() {
  switch (getMiniGame()) {
    case MINI_GAME_LINE_RIDER:
      return <LineRider />;
    case MINI_GAME_ANIMATION_STUDIO:
      return <AnimationStudio />;
    case MINI_GAME_TOWERS:
      return <Towers />;
    case MINI_GAME_SHADOWS:
      return <Shadows />;
    case MINI_GAME_FLIP_BOOK:
      return <FlipBook />;
    case MINI_GAME_CARGO_BOAT:
      return <CargoBoat />;
    case MINI_GAME_CODE_BLOCKS:
      return <CodeBlocks />;
    case MINI_GAME_CODING_CHALLENGE:
      return <CodingChallenge />;
    case MINI_GAME_IRRIGATION:
      return <Irrigation />;
    default:
      console.warn("no mini game provided through environment variables")
      return <FlipBook />;
  }
}
