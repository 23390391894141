import localeEN from './locale_en';
import localeAR from './locale_ar';

const locales = {
  en: {
    translation: localeEN,
  },
  ar: {
    translation: localeAR,
  },
};

export default locales;
