import IconButton from '@ilmiworld/ui/src/components/IconButton';
import ArrowRightIcon from '@ilmiworld/ui/src/assets/icons/icon_arrow_right.svg';

export default function NextButton({
  onClick,
  ariaLabel,
  large,
  disabled,
  ...props
}) {
  return (
    <IconButton
      onClick={onClick}
      color="orange"
      icon={ArrowRightIcon}
      ariaLabel={ariaLabel}
      large={large}
      disabled={disabled}
      withShadow
      {...props}
    />
  );
}
