import { useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { getToolEvent } from '@ilmiworld/analytics/dist/features/components/tool';
import { analyticsEnabled } from '@ilmiworld/ui/src/constants';

import { IS_IFRAME } from 'src/constants/miniGames';
import WorldMessageService from 'src/services/WorldMessageService';
import { SlideContext } from '../../InstructionsModal';

const pushStartEvent = (info, totalSlides) => {
  if (analyticsEnabled) {
    const toolInfo = {
      name: '',
      stepName: 'play-1',
      stepNumber: (totalSlides + 1).toString(),
      stepOption: '',
      stepType: 'play',
      type: 'game',
      ...info,
    };
    const toolEvent = getToolEvent('tool-start', toolInfo);

    if (IS_IFRAME) WorldMessageService.sendAnalyticsEvent(toolEvent);
  }
};

const pushCompleteEvent = (info, totalSlides) => {
  if (analyticsEnabled) {
    const toolInfo = {
      name: '',
      stepName: 'finish-1',
      stepNumber: (totalSlides + 2).toString(),
      stepOption: '',
      stepType: 'finish',
      type: 'game',
      ...info,
    };
    const toolEvent = getToolEvent('tool-complete', toolInfo);

    if (IS_IFRAME) WorldMessageService.sendAnalyticsEvent(toolEvent);
  }
};

function useAnalytics(info = {}, event = 'tool-start', context = SlideContext) {
  const { name, activeSlideIndex } = useContext(context);
  const actualStepNumber = activeSlideIndex + 1;
  const stepNumber = actualStepNumber.toString();

  const { ref, inView } = useInView({
    threshold: 0.75,
    onChange: (inView) => {
      if (inView && analyticsEnabled) {
        const toolInfo = {
          name: name,
          stepName: `directions-${stepNumber - 1}`,
          stepNumber,
          stepOption: '',
          stepType: 'instructions',
          type: 'game',
          ...info,
        };
        const toolEvent = getToolEvent(event, toolInfo);

        if (IS_IFRAME) WorldMessageService.sendAnalyticsEvent(toolEvent);
      }
    },
  });

  return {
    /** `ref` for `useInView`, impression will trigger on the element with this ref. */
    ref,
    inView,
  };
}

export default useAnalytics;
export { pushStartEvent, pushCompleteEvent };
