import styles from '../FeedbackModal.module.scss';
import cn from 'classnames';

export default function FeedbackModalContent({title, description, color}) {
  const feedbackModalContentStyles = cn(styles.feedbackModalContent, {
    [styles.red]: color === 'red',
  })

  return (
    <div className={styles.feedbackModalContainer}>
      <div className={feedbackModalContentStyles}>
        <div>
          <h1 className={styles.title} id="modalTitle">{title}</h1>
          <p>{description}</p>
        </div>
      </div>
    </div>
  )
}
