import React, {  isValidElement, cloneElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@ilmiworld/ui/src/components/Button';
import CloseButton from "@ilmiworld/ui/src/components/CloseButton";
import Modal from "@ilmiworld/ui/src/components/Modal";
import ModalContent from "@ilmiworld/ui/src/components/ModalContent";
import ModalBody from "@ilmiworld/ui/src/components/ModalBody";
import styles from './InstructionsModal.module.scss';
import {useRef, useState} from "react";
import cn from "classnames"
import { useTranslation } from 'react-i18next';

import BootstrapCarousel from 'bootstrap/js/dist/carousel';

export const SlideContext = React.createContext({ name: '', activeSlideIndex: 0 });

function Carousel({ name, children, active, handleClose }) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const carouselRef = useRef(null)
  const { t, i18n } = useTranslation();

  const indicatorStyles = cn(styles.dots, {
    [styles.hide]: activeSlideIndex === 0
  })
  const previousButtonStyles = cn(styles.previousButton, {
    [styles.hide]: activeSlideIndex === 0
  })
  const nextButtonStyles = cn(styles.nextButton, {
    [styles.hide]: activeSlideIndex === 0 || activeSlideIndex === (children.length - 1)
  })

  useEffect(() => {
    const carousel = carouselRef.current;
    function handleSlide(e) {
      setActiveSlideIndex(e.to)
    }

    if (carousel) {
      carousel.addEventListener('slide.bs.carousel', handleSlide);

      return () => {
        carousel.removeEventListener('slide.bs.carousel', handleSlide);
      }
    }
  }, [children]);

  useEffect(() => {
    if (!active) {
      // reset carousel
      const bsCarousel = new BootstrapCarousel(carouselRef.current);
      setActiveSlideIndex(0);
      bsCarousel.to(0);
    }
  }, [active])

  function handleOnStart() {
    const bsCarousel = new BootstrapCarousel(carouselRef.current);
    setActiveSlideIndex(1);
    bsCarousel.to(1);
  }

  const childrenWithProps = children.map((child, index) => {
    if (isValidElement(child)) {
      return (
        <div
          key={index}
          className={`carousel-item ${index === 0 ? 'active' : ''}`}
        >
          <React.Fragment >
            {cloneElement(child, { onStart: handleOnStart, onSkip: handleClose })}
          </React.Fragment>
        </div>
      )
    }

    return child;
  });



  return (
    <SlideContext.Provider value={{ name, activeSlideIndex }}>
      <div
        ref={carouselRef}
        id={`instructionsCarousel`}
        className={`carousel slide ${styles.carousel} ${ i18n.dir()  === 'rtl' ? styles.rtl : ''}`}
        data-bscarousel-inner-ride="carousel"
        data-bs-interval="false"
        data-bs-wrap="false"
      >
        <ol className={`${indicatorStyles} carousel-indicators`}>
          {childrenWithProps.map((slide, index) => (
            <li data-bs-target="#instructionsCarousel" data-bs-slide-to={index} key={index} className={`${index === 0 ? 'active' : ''}`}/>
          ))}
        </ol>
        <div className={`carousel-inner ${styles.carouselInner}`}>
          {childrenWithProps}
        </div>
        <Button
          inView={activeSlideIndex > 0}
          ariaLabel={t('instructions.previous_slide')}
          className={`${previousButtonStyles} carousel-control-prev`}
          type="button"
          data-bs-target={`#instructionsCarousel`}
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </Button>
        <Button
          inView={activeSlideIndex > 0}
          ariaLabel={t('instructions.next_slide')}
          className={`${nextButtonStyles} carousel-control-next`}
          type="button"
          data-bs-target={`#instructionsCarousel`}
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </Button>

      </div>
    </SlideContext.Provider>
  )
}

Carousel.propTypes = {
  name: PropTypes.string.isRequired,
};

export default function InstructionsModal({ name, active, onModelHidden, onClose, children }) {
  const { t } = useTranslation();

  return (
    <Modal
      active={active}
      size="xl"
      type="transparent"
      onModelHidden={onModelHidden}
      backgroundBlur={true}
    >
      <>
        <ModalContent>
          <ModalBody>
            <div className={styles.closeButtonContainer} >
              <CloseButton
                ariaLabel={t('instructions.close')}
                onClick={onClose}
                color="red"
              />
            </div>
            <Carousel name={name} children={children} active={active} handleClose={onClose} />
          </ModalBody>
        </ModalContent>
      </>
    </Modal>
  );
}

InstructionsModal.propTypes = {
  name: PropTypes.string.isRequired,
};
