import { useTranslation } from 'react-i18next';

import { MINI_GAME_IRRIGATION } from 'src/constants/miniGames';
import InstructionsModal from "src/components/InstructionsModal";
import InstructionsSlideStart from "src/components/InstructionsModal/Slides/InstructionsSlideStart";
import InstructionsSlide from "src/components/InstructionsModal/Slides/InstructionsSlide";
import InstructionsSlideEnd from "src/components/InstructionsModal/Slides/InstructionsSlideEnd";
import { pushStartEvent } from 'src/components/InstructionsModal/Slides/hooks/useAnalytics';

import Instructions1 from "../../../assets/images/instructions/instruction_1.png"
import Instructions2 from "../../../assets/images/instructions/instruction_2.png"
import Instructions3 from "../../../assets/images/instructions/instruction_3.png"
import InstructionsEnd from "../../../assets/images/instructions/instruction_end.png"



function CodeBlocksInstructions({active, onClose}) {
  const { t } = useTranslation();


  const handleClose = () => {
    /** Analytics */
    pushStartEvent({ name: MINI_GAME_IRRIGATION }, 5);
    onClose();
  };

  

  return (
    <InstructionsModal
      name={MINI_GAME_IRRIGATION}
      active={active}
      onClose={handleClose}
    >
      <InstructionsSlideStart
        title={t('irrigation.instructions.start.title')}
        text={t('irrigation.instructions.start.text')}
        buttonLabel={t('irrigation.instructions.start.button_label')}
      />

        <InstructionsSlide text={t('irrigation.instructions.slide1.text')} image={Instructions1} />
        <InstructionsSlide text={t('irrigation.instructions.slide2.text')} image={Instructions2} />
        <InstructionsSlide text={t('irrigation.instructions.slide3.text')} image={Instructions3} />
      
      <InstructionsSlideEnd
        text={t('irrigation.instructions.end.text')}
        buttonLabel={t('irrigation.instructions.end.button_label')}
        image={InstructionsEnd}
        onClose={handleClose}
      />
    </InstructionsModal>
  );
}

export default CodeBlocksInstructions;
