import cn from "classnames"
import styles from "./ScoreModalContent.module.scss";
import RobotImage0 from "./assets/robot-0.png";
import RobotImage1 from "./assets/robot-1.png";
import RobotImage2 from "./assets/robot-2.png";
import RobotImage3 from "./assets/robot-3.png";
import RobotImage4 from "./assets/robot-4.png";
import { useTranslation } from 'react-i18next';
import {useEffect, useState} from "react";
import Stars from "src/components/Stars";
import WorldMessageService from "src/services/WorldMessageService";

function getStarsFeedback(stars, gameKey) {
  if (stars === 0) {
    return {
      title: `${gameKey}.result_modal.game_over.title`,
      subtitle: `${gameKey}.result_modal.game_over.subtitle`,
      image: RobotImage0
    };
  } else if (stars === 1) {
    return {
      title: `${gameKey}.result_modal.score.1.title`,
      subtitle: `${gameKey}.result_modal.score.1.subtitle`,
      image: RobotImage1
    };
  } else if (stars === 2) {
    return {
      title: `${gameKey}.result_modal.score.2.title`,
      subtitle: `${gameKey}.result_modal.score.2.subtitle`,
      image: RobotImage2
    };
  } else if (stars === 3) {
    return {
      title: `${gameKey}.result_modal.score.3.title`,
      subtitle: `${gameKey}.result_modal.score.3.subtitle`,
      image: RobotImage3
    }
  }
  return {
    title: `${gameKey}.result_modal.score.4.title`,
    subtitle: `${gameKey}.result_modal.score.4.subtitle`,
    image: RobotImage4
  };
}

export default function ScoreModalContent({ stars, gameKey, score }) {
  const {t} = useTranslation();
  const [scoreFeedback, setScoreFeedback] = useState();

  const backgroundStyles = cn(styles.background, {
    [styles.gameOver]: stars === 0
  })

  useEffect(() => {
    setScoreFeedback(getStarsFeedback(stars, gameKey));
    if(stars > 0) {
      WorldMessageService.sendGameStars(stars, gameKey, score);
    }
  }, [stars])

  return (
    <div className={styles.scoreModalContainer}>
      <div className={backgroundStyles}/>
      <div className={styles.scoreModalContent}>
        <img
          src={scoreFeedback?.image}
          className={styles.image}
          aria-hidden="true"
          alt=""
        />
        <Stars className={styles.stars} earned={stars}/>
        <h1 className={styles.title} id="modalTitle">{t(scoreFeedback?.title)}</h1>
        <p className={styles.subtitle}>
          {t(scoreFeedback?.subtitle)}
        </p>
      </div>
    </div>
  )
}
