var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// eslint-disable-next-line import/no-cycle
import { getPageImpression } from '../..';
import { langRegex } from '../../constants';
var getNameFromLocation = function (location) {
    var _a;
    var locationWithoutLang = (_a = location === null || location === void 0 ? void 0 : location.replace(langRegex, '/')) !== null && _a !== void 0 ? _a : '';
    return locationWithoutLang === '/'
        ? 'steampark'
        : "steampark".concat(locationWithoutLang);
};
var getName = function (location) {
    var _a;
    var nameFromLocation = getNameFromLocation(location);
    return (_a = nameFromLocation.split('/').pop()) !== null && _a !== void 0 ? _a : '';
};
var getDataLayer = function () {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.adobeDataLayer = window.adobeDataLayer || [];
    return window.adobeDataLayer;
};
var getGlobalInfo = function (pageInfo) {
    var pageInfoWithData = __assign(__assign({}, pageInfo), { name: getNameFromLocation(window.location.pathname) });
    var pageImpression = getPageImpression(pageInfoWithData);
    return {
        pageInfo: __assign({}, pageImpression.pageInfo),
    };
};
/** TODO: type does not work when compiled, only works inside this project. Use with caution, or use wrappers (`pushPageEvent()`) */
var push = function (e, log) {
    if (log === void 0) { log = false; }
    if (log)
        console.info(e);
    return getDataLayer().push(e);
};
export { getDataLayer, getNameFromLocation, getName, getGlobalInfo, push };
