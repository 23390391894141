import styles from '../FeedbackModal.module.scss';
import NextButton from "src/components/NextButton";

export default function FeedbackModalFooter({resultImage, onNext}) {
  return (
    <div className={styles.feedbackModalFooter}>
      {resultImage && (
        <div className={styles.assetContainer}>
          <img src={resultImage} className={styles.image} aria-hidden="true" alt=""/>
        </div>
      )}
      <NextButton large onClick={onNext} />
    </div>
  )
}
