import Modal from "@ilmiworld/ui/src/components/Modal";
import ModalContent from "@ilmiworld/ui/src/components/ModalContent";
import FeedbackModalContent from "src/components/FeedbackModal/FeedbackModalContent";
import styles from "./FeedbackModal.module.scss";
import FeedbackModalFooter from "src/components/FeedbackModal/FeedbackModalFooter";
import ExitButton from "src/components/ExitButton";

export default function FeedbackModal({ active, title, description, resultImage, onNextClick, color }) {
  return (
    <Modal
      active={active}
      size="fullScreen"
      type="transparent"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <ModalContent>
        <div className={styles.container}>
          <div className={styles.feedbackModalHeader}>
            <div />
            <ExitButton />
          </div>
          <div className={styles.feedbackModalBody}>
            <FeedbackModalContent
              title={title}
              description={description}
              color={color}
            />
          </div>
          <FeedbackModalFooter
            resultImage={resultImage}
            onNext={onNextClick}
          />
        </div>
      </ModalContent>
    </Modal>
  )
}
