import styles from "src/components/InstructionsModal/InstructionsModal.module.scss";
import Button from "@ilmiworld/ui/src/components/Button";
import RobotImageAmazing from '@ilmiworld/ui/src/assets/images/mascot/mascot_instructions.png';
import { useTranslation } from 'react-i18next';
import useAnalytics from "../hooks/useAnalytics";

export default function InstructionsSlideStart({ onStart, onSkip, title, text, buttonLabel }) {
  const { t } = useTranslation();
  const skipLabel = t('ui.skip_btn');
  const { ref } = useAnalytics({
    stepName: 'directions-intro',
    stepOption: `${buttonLabel}/${skipLabel}`,
  });

  return (
    <div ref={ref} className={`${styles.startSlide} row justify-content-center align-items-center`}>
      <div className="col-12 order-1 col-lg-4 order-lg-0 col-xl-6">
        <img className={styles.instructionMascot} alt={''} src={RobotImageAmazing} aria-hidden="true" />
      </div>
      <div className="col-12 order-0 col-lg-8 order-lg-1 col-xl-6">
        <h1>{title}</h1>
        <p>{text}</p>
        <Button
          onClick={onStart}
          color={'orange'}
          fullWidth={true}
        >
          {buttonLabel}
        </Button>
        <Button
          onClick={onSkip}
          color={'none'}
          fullWidth={true}
        >
          {skipLabel}
        </Button>
      </div>
    </div>
  );
}
