import cn from "classnames"
import styles from "./Stars.module.scss";
import FullStarIcon from "@ilmiworld/ui/src/assets/icons/icon_star_full.svg";
import EmptyStarIcon from "@ilmiworld/ui/src/assets/icons/icon_star_empty.svg";
import { useTranslation } from 'react-i18next';

export default function Stars({earned, className={}}) {
  const { t } = useTranslation();
  const classNames = cn(styles.container, className)

  return (
    <div className={classNames}>
      {[1,2,3,4].map(star => {
        const achievedStar = earned >= star;
        return (
          <img
            key={star}
            className={styles[`star${star}`]}
            src={achievedStar ? FullStarIcon : EmptyStarIcon}
            alt={t(`stars.${achievedStar ? 'achieved_star' : 'empty_star'}`)}
          />
        );
      })}
    </div>
  )
}
