import Header from "src/components/Header";
import styles from './HeaderUI.module.scss'
import Clock from "src/MiniGames/Toys/Irrigation/Components/UI/Clock"

export default function HeaderUI({onShowInstructions}) {
  return (
    <div className={styles.HeaderUI}>
      <Header
        onInfoClick={onShowInstructions}
      >
        <Clock />
      </Header>
    </div>
  )
}
