import cn from "classnames";
import styles from './Icon.module.scss';

function Icon({ children, withShadow, flipRTL = true }) {
  const iconClassNames = cn(styles.iconContainer, {
    [styles.mirror]: flipRTL,
    [styles.withShadow]: withShadow,
  })
  return (
    <span className={iconClassNames}>
      {children}
    </span>
  );
}

export default Icon;
