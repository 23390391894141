import IconButton from "@ilmiworld/ui/src/components/IconButton";
import InfoIcon from "@ilmiworld/ui/src/assets/icons/icon_info.svg";

function InstructionsButton({ onClick, ariaLabel, color = 'blue', ...props }) {
  return (
      <IconButton
        onClick={onClick}
        color={color}
        icon={InfoIcon}
        ariaLabel={ariaLabel}
        small
        iconSize={12}
        {...props}
      />
  );
}

export default InstructionsButton;
